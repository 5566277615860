import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription, interval } from 'rxjs';
import { EmailContract, EmailUpdateRequest } from '@app-types/api/email';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByEmailFileIdRequest,
  BaseGetByIdRequest,
  BaseGetCollectionByIdRequest,
} from '@app-types/base/base';
import { TagClient } from '@app-services/api/clients/tag.client';
import { EmailTagClient } from '@app-services/api/clients/email-tag.client';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { PermissionType } from '@app-types/enums/permission-type';
import { PermissionService } from '@app-services/permission/permission.service';
import { environment } from '@app-environments/environment';
import { filter } from 'rxjs/operators';
import objectHash from 'object-hash';
import { EmailBodyContentType } from '@app-types/enums/email-body-content-type';
import { EmailState } from '@app-types/enums/email-state';
import sanitizeHtml from 'sanitize-html';
import { EmailClient } from '@app-services/api/clients/email-client';
import { SnackbarService } from '@app-services/snackbar.service';
import { Location, NgStyle } from '@angular/common';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FileClient } from '@app-services/api/clients/file.client';
import { MatDialog } from '@angular/material/dialog';
import { EmailContactsDialogComponent } from '../email-contacts-dialog/email-contacts-dialog.component';
import { ContactClient } from '@app-services/api/clients/contact.client';
import { ErrorDialogComponent } from '../../common/error-dialog/error-dialog.component';
import { ConfirmDialogComponent } from '../../common/confirm-dialog/confirm-dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TemplateGetByIdContract } from '@app-types/api/template';
import { AttachmentState } from 'src/app/types/enums/attachment-state';
import { AttachmentClient } from 'src/app/services/api/clients/attachment.client';
import { EmailAuditClient } from 'src/app/services/api/clients/email-audit-client';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EmailTagsComponent } from '../../common/email/email-tags/email-tags.component';
import { MatInputModule } from '@angular/material/input';
import { TagsComponent } from '../../common/tags/tags.component';
import { EMAIL_REGEX } from '@app-shared/constants/regex';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DrawerService } from '@app-services/drawer.service';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { EmailMenuContent } from '@app-types/enums/emails';
import { EmailBodyComponent } from '../../common/email/email-body/email-body.component';
import { EmailSidenavComponent } from '../../common/email/email-sidenav/email-sidenav.component';
import {
  Attachment,
  AttachmentUploadContract,
} from '@app-types/api/Attachment';
import { angularEmailValidator } from '@app-utils/custom-validator';
import { SkeletonEmailComponent } from '@app-components/common/skeletons/skeleton-email/skeleton-email.component';
import { ErrorsPipe } from '@app-pipes/error-code.pipe';
import { removeCommentHtml } from '@app-utils/parser';

@Component({
  selector: 'app-email-create-or-update',
  templateUrl: './email-create-or-update.component.html',
  styleUrls: ['./email-create-or-update.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    LoadingButtonComponent,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgStyle,
    MatSlideToggleModule,
    TranslateModule,
    EmailTagsComponent,
    MatInputModule,
    TagsComponent,
    EmailContactsDialogComponent,
    MatTooltipModule,
    ErrorDialogComponent,
    EmailBodyComponent,
    EmailSidenavComponent,
    SkeletonEmailComponent,
  ],
})
export class EmailCreateOrUpdateComponent implements OnInit, OnDestroy {
  @Input() public mailAccountId: number;
  @Input() public emailId?: number;
  @Input() public useMobileView: boolean;
  @Output() public submitted = new EventEmitter();
  @Output() public deleteFromData = new EventEmitter<any>();
  @Output() public changeDraftData = new EventEmitter<any>();
  @ViewChild('uploader') uploader: ElementRef;
  tagCtrl = new UntypedFormControl();
  public email: EmailContract;
  public checkAttachmentsInterval: ReturnType<typeof setInterval>;
  public isLoading = true;
  public isContactsLoadingTo = false;
  public isContactsLoadingCc = false;
  public cannotLoadData = false;
  public attachmentState = AttachmentState;
  public form: UntypedFormGroup;
  public senderAddress: string;
  public toList: string[] = [];
  public ccList: string[] = [];
  public initToList: string[] = [];
  public initCcList: string[] = [];
  public attachmentFiles: AttachmentUploadContract[] = [];
  private fileId: number;
  public canSend = false;
  public canValidate = false;
  public existedTags: string[] = [];
  public thisTags: string[] = [];
  public menuIsOpened = false;
  public menuContent: EmailMenuContent | null;
  private autoSaveSubscription: Subscription;
  public previousHash: string;
  public body = '';
  public templateSubject: string;
  public templateBody: string;
  public showCc = false;
  public submitInProgress = false;
  public saveInProgress = false;
  public cannotSaveData = false;
  public contactsListTo: string[] = [];
  public contactsListCc: string[] = [];
  public initToContacts: string[] = [];
  public initCcContacts: string[] = [];
  public contactCtrl = new UntypedFormControl();
  public isNotesChanged = false;
  public downloadingFileIds: number[] = [];
  public isSplitEmail = false;
  public isSaveOnClose = true;
  public isNeedToUpdate = true;
  public newTags: string[] = [];
  public currentTagsElement: number | null = null;
  pipeErrors = new ErrorsPipe();

  constructor(
    private tagClient: TagClient,
    private emailTagClient: EmailTagClient,
    private mailAccountClient: MailAccountClient,
    private permissionService: PermissionService,
    private emailClient: EmailClient,
    private attachmentClient: AttachmentClient,
    private translate: TranslateService,
    private snackbarHelper: SnackbarService,
    private emailAuditClient: EmailAuditClient,
    private location: Location,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    public matchError: MatchError,
    public contactClient: ContactClient,
    private fileClient: FileClient,
    private drawerService: DrawerService,
    public userWorkspaceService: UserWorkspaceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.canSend = await this.permissionService.hasPermissionOver(
      null,
      this.mailAccountId,
      PermissionType.CanSendEmail
    );
    this.canValidate = await this.permissionService.hasPermissionOver(
      null,
      this.mailAccountId,
      PermissionType.CanValidateEmail
    );
    await this.init();
  }

  async init(): Promise<void> {
    try {
      await this.loadData();
      if (this.email.cc && this.email.cc.length > 0) {
        this.showCc = true;
      }
      if (this.useMobileView) {
        this.showCc = true;
      }
      this.isSplitEmail = !!this.email?.splitSend;
      this.form = new UntypedFormGroup({
        to: new UntypedFormControl(''),
        cc: new UntypedFormControl(''),
        bcc: new UntypedFormControl(''),
        subject: new UntypedFormControl(this.email.subject, [
          Validators.maxLength(2048),
        ]),
      });

      this.previousHash = this.calculateDraftHash();
      this.autoSaveSubscription = interval(environment.autoSaveIntervalMs)
        .pipe(
          filter(() => {
            if (this.isLoading || this.cannotLoadData) {
              return false;
            }
            const newHash = this.calculateDraftHash();
            const hashesAreNotEqual = this.previousHash !== newHash;
            if (hashesAreNotEqual) {
              this.previousHash = newHash;
            }
            return hashesAreNotEqual;
          })
        )
        .subscribe(() => {
          this.updateDraft(true).then();
        });
      this.checkAttachmentsInterval = setInterval(() => {
        this.emailId && this.checkAttachments(this.emailId);
      }, 1000 * 10);
    } catch (e) {
      this.matchError.logError(e);
    }
  }

  addNewTag(): void {
    if (this.tagCtrl.value) {
      this.thisTags.push(this.tagCtrl.value);
      const uniqueTags = this.thisTags.filter(
        (e, i, arr) => arr.indexOf(e) === i
      );

      if (uniqueTags.length !== this.thisTags.length) {
        this.thisTags = uniqueTags;
      }
    }
  }

  tagsEmail():
    | undefined
    | {
        emailsId: number[];
        tags: string[];
      } {
    let data = undefined;
    if (this.tagCtrl.value) {
      this.thisTags.push(this.tagCtrl.value);
      const uniqueTags = this.thisTags.filter(
        (e, i, arr) => arr.indexOf(e) === i
      );

      if (uniqueTags.length !== this.thisTags.length) {
        this.thisTags = uniqueTags;
      }
      this.email.emailId &&
        (data = {
          emailsId: [this.email.emailId],
          tags: this.thisTags,
        });
    }
    return data;
  }

  ngOnDestroy(): void {
    if (this.isSaveOnClose) {
      this.saveInProgress = true;
      this.updateDraft(true);
      this.saveInProgress = false;
      this.submitted.emit(this.tagsEmail());
    }
    this.isNeedToUpdate = false;
    this.autoSaveSubscription?.unsubscribe();
    clearInterval(this.checkAttachmentsInterval);
  }

  valueChangedEventEmitter(event: string): void {
    this.body = event;
  }

  setCurrentTagsElement(value: number | null = null): void {
    if (value) {
      this.currentTagsElement = value;
      return;
    }
    const activeEle = document?.activeElement?.parentElement?.parentElement;
    const tagsTags = document.getElementById('tags-tags');
    const tagsCc = document.getElementById('tags-cc');
    const tagsTo = document.getElementById('tags-to');
    if (!activeEle) return;
    if (tagsTo?.contains(activeEle)) {
      this.currentTagsElement = 1;
    } else if (tagsCc?.contains(activeEle)) {
      this.currentTagsElement = 2;
    } else if (tagsTags?.contains(activeEle)) {
      this.currentTagsElement = 3;
    } else {
      this.currentTagsElement = null;
    }
  }

  public async getContactsTo(value: string): Promise<any> {
    this.isContactsLoadingTo = true;
    try {
      const response = !value
        ? await this.contactClient.getForAccount(
            new BaseGetCollectionByIdRequest(this.email.mailAccountId, 1, 50)
          )
        : await this.contactClient.searchForAccount(
            new BaseCollectionSearchByIdRequest(
              this.email.mailAccountId,
              1,
              50,
              value
            )
          );
      this.contactsListTo = response.data.map(e => e.email);
    } catch (e) {
      this.matchError.logError(e);
    } finally {
      this.isContactsLoadingTo = false;
    }
  }

  public async getContactsCc(value: string): Promise<any> {
    this.isContactsLoadingCc = true;
    try {
      const response = !value
        ? await this.contactClient.getForAccount(
            new BaseGetCollectionByIdRequest(this.email.mailAccountId, 1, 50)
          )
        : await this.contactClient.searchForAccount(
            new BaseCollectionSearchByIdRequest(
              this.email.mailAccountId,
              1,
              50,
              value
            )
          );
      this.contactsListCc = response.data.map(e => e.email);
    } catch (e) {
      this.matchError.logError(e);
    } finally {
      this.isContactsLoadingCc = false;
    }
  }

  public splitEmailToggle(): void {
    this.isSplitEmail = !this.isSplitEmail;
  }

  public onHideNotificationsChanged($event: boolean): void {
    this.isSplitEmail = $event;
  }

  public openErrorDialog(
    title: string,
    description: string,
    subTitle?: string
  ): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      autoFocus: false,
      data: { title, description, subTitle },
    });
  }

  public openContactsDialog(isCc = false): void {
    const dialogRef = this.dialog.open(EmailContactsDialogComponent, {
      width: '800px',
      maxHeight: '600px',
      minHeight: '600px',
      data: { mailAccountId: this.email.mailAccountId },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.contactCtrl.enable();
      if (result && !isCc) {
        this.toList = this.toList.concat(result);
        const notValidEmails: string[] = [];
        this.toList.forEach((e, i, arr) =>
          arr.indexOf(e) === i ? null : notValidEmails.push(e)
        );
        this.toList = this.toList.filter((e, i, arr) => arr.indexOf(e) === i);
        this.initToList = this.toList;
        if (notValidEmails.length) {
          this.openErrorDialog(
            'These emails already have been added',
            `${notValidEmails.join('; ')}`
          );
        }
      }
      if (result && isCc) {
        this.ccList = this.ccList.concat(result);
        const notValidEmails: string[] = [];
        this.ccList.forEach((e, i, arr) =>
          arr.indexOf(e) === i ? null : notValidEmails.push(e)
        );
        this.ccList = this.ccList.filter((e, i, arr) => arr.indexOf(e) === i);
        this.initCcList = this.ccList;
        if (notValidEmails.length) {
          this.openErrorDialog(
            'These emails already have been added',
            `${notValidEmails.join('; ')}`
          );
        }
      }
    });
  }

  private async createDraft(): Promise<EmailContract> {
    const createRequest = {
      from: null,
      to: null,
      cc: null,
      subject: null,
      body: null,
      attachmentFiles: new Array<Attachment>(),
      bodyContentType: EmailBodyContentType.Html,
      mailAccountId: this.mailAccountId,
      tags: [],
      notes: [],
      splitSend: false,
    };

    const response = await this.emailClient.createDraft(createRequest);
    return response.email;
  }

  public templateSelected(value: TemplateGetByIdContract): void {
    this.templateSubject = value.subject;
    this.templateBody = value.body;
    this.templateBody = sanitizeHtml(this.templateBody, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
      allowedAttributes: false,
      allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
    });
    if (this.form.value.subject || this.body) {
      this.openConfirmDialog('bodyAndSubjectChangeConfirmation');
    } else {
      this.changeData();
    }
  }

  public changeData(): void {
    if (this.body?.length && this.body.slice(0, 8) === '<br />--') {
      this.templateBody = this.templateBody + this.body;
    } else {
      if (this.templateSubject) {
        this.form?.get('subject')?.setValue(this.templateSubject);
      } else {
        this.form?.get('subject')?.setValue('');
      }
    }

    this.body = this.templateBody;
    this.templateSubject = '';
    this.templateBody = '';
    if (this.useMobileView) {
      this.menuIsOpened = false;
    }
  }

  public async checkAttachments(emailId: number): Promise<void> {
    const needToCheck = this.attachmentFiles.some(
      a =>
        a.fileState === AttachmentState.CheckInProgress ||
        a.fileState === AttachmentState.Uploaded
    );
    if (!needToCheck) {
      return;
    }
    try {
      const attachmentsResponse = await this.attachmentClient.getById(
        new BaseGetByIdRequest(emailId)
      );
      const attachmentsDifference: Attachment[] = [];
      const emailFileIds = attachmentsResponse.data.map(a => a.fileId);
      this.attachmentFiles.forEach(a => {
        const included = emailFileIds.includes(a.fileId);
        if (!included) {
          attachmentsDifference.push(a);
        }
      });

      if (attachmentsDifference.length && this.emailId) {
        const auditResponse = await this.emailAuditClient.getByEmail(
          new BaseGetCollectionByIdRequest(this.emailId, 1, 100)
        );
        const auditMetadatas = auditResponse.data.map(e => e.metadata);
        const attachmentsDifferenceInMetadata = attachmentsDifference.filter(
          e => auditMetadatas.some(m => m === e.fileName)
        );
        console.log('auditResponse', auditMetadatas);
        if (attachmentsDifferenceInMetadata.length) {
          console.log(
            'files containing viruses have been deleted:',
            attachmentsDifference,
            auditResponse
          );
          this.attachmentFiles = this.attachmentFiles.filter(
            a =>
              !attachmentsDifferenceInMetadata.some(m => m.fileId === a.fileId)
          );
          const files = attachmentsDifferenceInMetadata
            .map(e => e.fileName)
            .join(', ');

          this.openErrorDialog(
            'error',
            this.translate.instant('virusesDetectedFirst') +
              ' "' +
              files +
              '" ' +
              this.translate.instant('virusesDetectedSecond')
          );
        }
      }

      const findAttachmentFromResponse = (fileId: number) => {
        return attachmentsResponse.data.find(a => a.fileId === fileId);
      };

      this.attachmentFiles = this.attachmentFiles.map(a => {
        const attachmentFromResponse =
          a.fileId && findAttachmentFromResponse(a.fileId);
        return attachmentFromResponse
          ? { ...a, fileState: attachmentFromResponse.fileState }
          : a;
      });
    } catch (error) {
      console.log(error);
    }
  }

  async audit(): Promise<void> {
    this.menuContent = EmailMenuContent.Audit;
    this.menuIsOpened = true;
  }

  private async getEmail(emailId: number): Promise<EmailContract> {
    const emailResponse = await this.emailClient.getById(
      new BaseGetByIdRequest(emailId)
    );
    return emailResponse.email;
  }

  public async loadData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadData = false;
    try {
      this.email = this.emailId
        ? await this.getEmail(this.emailId)
        : await this.createDraft();
      this.emailId = this.email.emailId;

      const tagsResponse = await this.tagClient.getForMailAccount(
        new BaseGetByIdRequest(this.email.mailAccountId)
      );
      this.existedTags = tagsResponse.data.map(c => c.tag);

      const contactsResponse = await this.contactClient.getForAccount(
        new BaseGetCollectionByIdRequest(this.email.mailAccountId, 1, 50)
      );

      this.contactsListTo = contactsResponse.data.map(c => c.email);
      this.contactsListCc = contactsResponse.data.map(c => c.email);
      this.initToContacts = this.contactsListTo;
      this.initCcContacts = this.contactsListCc;
      const emailTagsResponse = await this.emailTagClient.getEmailTags(
        new BaseGetByIdRequest(this.email.emailId ?? null)
      );

      this.thisTags = emailTagsResponse.data.map(c => c.tag);
      this.newTags = this.thisTags;

      if (this.email.to) {
        this.toList = this.email.to.split(';');
        this.initToList = this.email.to.split(';');
      }
      if (this.email.cc) {
        this.ccList = this.email.cc.split(';');
        this.initCcList = this.email.cc.split(';');
      }

      this.email.from && (this.senderAddress = this.email.from);
      if (this.email.body) {
        // add htmlRegex to fix bug if not valid html (remove all Html code between <!-- and -->)
        this.body = removeCommentHtml(this.email.body);
        this.body = sanitizeHtml(this.body, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
          allowedAttributes: false,
          allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
        });
      } else {
        this.body = '';
      }

      this.attachmentFiles = this.email.attachmentFiles.map(a => ({
        progress: 100,
        attachmentId: a.attachmentId,
        fileId: a.fileId,
        fileName: a.fileName,
        mimeType: a.mimeType,
        size: a.size,
        fileState: a.fileState,
      }));
    } catch (e) {
      console.log(e);
      this.matchError.logError(e);
      this.cannotLoadData = true;
    } finally {
      this.isLoading = false;
    }
  }

  calculateDraftHash(): any {
    const obj = {
      to: this.toList,
      cc: this.ccList,
      subject: this.form.get('subject')?.value,
      body: this.body || '',
      tags: this.thisTags,
      attachments: this.attachmentFiles.map(f => f.fileId),
    };

    return objectHash(obj);
  }

  async onClickReset(): Promise<void> {
    this.attachmentFiles = [];
    this.toList = [];
    this.ccList = [];
    this.newTags = [];
    this.thisTags = [];
    this.form.get('subject')?.setValue('');
    this.body = '';
    this.emailId &&
      (await this.emailTagClient.saveTags({
        emailsId: [this.emailId],
        tags: this.thisTags,
      }));
    await this.updateDraft(true);
  }

  public openConfirmDialog(name: string, title?: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: { name, isDontNeedConfirmWord: true, title },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (name === 'resetSubtitle') {
          this.onClickReset();
        } else {
          this.changeData();
        }
      }
    });
  }

  fileIsUploading(): boolean {
    return this.attachmentFiles.filter(x => x.progress < 100).length !== 0;
  }

  public setTags(tags: string[]): void {
    this.newTags = tags;
  }

  async updateDraft(autoSaving: boolean, setStateDraft = false): Promise<void> {
    if ((this.submitInProgress && autoSaving) || this.isLoading) {
      return;
    }
    if (autoSaving) {
      console.info(
        '%c Email autosaving... ',
        'background: #000000; color: #00bfff'
      );
    }

    try {
      const updateRequest: EmailUpdateRequest = {
        attachmentFiles: new Array<Attachment>(),
        from: this.senderAddress,
        to: this.toList.join(';'),
        cc: this.ccList.join(';'),
        subject: this.form.controls.subject.value,
        body: this.body,
        bodyContentType: EmailBodyContentType.Html,
        emailId: this.email.emailId,
        tags: this.newTags,
      };

      if (
        !updateRequest.to &&
        !updateRequest.cc &&
        !updateRequest.attachmentFiles.length &&
        !updateRequest.subject &&
        !updateRequest.body &&
        !updateRequest.tags.length &&
        !setStateDraft
      ) {
        if (this.email.state === EmailState.Draft) {
          updateRequest.state = EmailState.Draft;
        } else {
          if (this.email.state !== EmailState.VirusDetected) {
            updateRequest.state = EmailState.PreDraft;
          } else {
            updateRequest.state = EmailState.Draft;
          }
        }
      } else {
        updateRequest.state = EmailState.Draft;
      }
      this.email.state = updateRequest.state;
      updateRequest.splitSend = this.isSplitEmail;
      const attachments = this.attachmentFiles;
      attachments
        .filter(e => e?.fileId)
        .forEach(file => {
          const emailAttachment = {
            fileId: file.fileId,
            fileName: file.fileName,
            mimeType: file.mimeType,
            size: file.size,
          };
          updateRequest.attachmentFiles.push(emailAttachment);
        });
      await this.emailClient.update(updateRequest);
      const emailId = this.emailId;
      const isNotesChanged = this.isNotesChanged;
      this.changeDraftData.emit({ emailId, updateRequest, isNotesChanged });
      if (autoSaving) {
        console.info(
          '%c Success autosaving ',
          'background: #000000; color: #00ffbf'
        );
      }
    } catch (e) {
      this.matchError.errorHandler(e);
      if (autoSaving) {
        // this.snackbarHelper.openSnackBar('Cannot autosave email');
        console.info(
          '%c Failed autosaving ',
          'background: #000000; color: #ff4000'
        );
      } else {
        throw e;
      }
      this.matchError.logError(e);
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public validateForm(): boolean {
    if (!this.form) {
      return false;
    }
    return this.form.valid && this.validateTo() && this.validateCc();
  }

  private validateTo(): boolean {
    if (this.toList.length === 0) {
      return false;
    }

    for (const to of this.toList) {
      if (!this.validateEmail(to)) {
        return false;
      }
    }
    return true;
  }

  validateEmail(email: string): boolean {
    return !!String(email).toLowerCase().match(EMAIL_REGEX);
  }

  private validateCc(): boolean {
    for (const cc of this.ccList) {
      if (!this.validateEmail(cc)) {
        return false;
      }
    }
    return true;
  }

  getEmailChipColor(email: string): any {
    if (angularEmailValidator(email)) {
      return 'primary';
    } else {
      return 'warn';
    }
  }

  isFileDownloading(fileId?: number): boolean {
    return !fileId ? false : this.downloadingFileIds.includes(fileId);
  }

  async getAttachmentFile(file: AttachmentUploadContract): Promise<void> {
    try {
      if (!file.fileId || !this.emailId) return;
      this.downloadingFileIds.push(file.fileId);
      const request = new BaseGetByEmailFileIdRequest(
        file.fileId,
        this.emailId
      );

      await this.fileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingFileIds = this.downloadingFileIds.filter(
        e => e !== file.fileId
      );
    }
  }

  public isEveryFileUploaded(): boolean {
    return this.attachmentFiles.every(e => e?.fileId);
  }

  addAttachmentFile(files: File[]): void {
    for (const selectedFile of files) {
      const uploadFile = {
        fileName: selectedFile.name,
        size: selectedFile.size,
        mimeType: selectedFile.type,
        progress: 0,
        fileState: this.attachmentState.CheckInProgress,
      };

      if (!uploadFile.mimeType) {
        uploadFile.mimeType = 'application/octet-stream';
      }

      if (this.attachmentFiles.length > 0) {
        const existAttachment = this.attachmentFiles.filter(
          x => x.fileName === selectedFile.name
        );
        if (existAttachment.length === 0) {
          const newAttachments = this.attachmentFiles.filter(
            x => x.fileName !== selectedFile.name
          );
          newAttachments.push(uploadFile);
          this.attachmentFiles = newAttachments;
        }
      } else {
        const newAttachments = this.attachmentFiles.filter(
          x => x.fileName !== selectedFile.name
        );
        newAttachments.push(uploadFile);
        this.attachmentFiles = newAttachments;
      }

      const notUploadFiles = this.attachmentFiles.filter(
        x => x.fileName === selectedFile.name && x.progress === 0
      );
      if (notUploadFiles.length !== 0) {
        const reader = new FileReader();
        reader.onload = () => {
          const content = (reader.result as string).split(',')[1];
          this.uploadFile(selectedFile.name, uploadFile.mimeType, content);
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  }

  addAttachmentFileFromButton(event: any): void {
    this.addAttachmentFile(event.target.files);
    this.uploader.nativeElement.value = '';
  }

  uploadFile(fileName: string, mimeType: string, content: string): void {
    if (!this.emailId) return;
    const subscription = this.fileClient
      .upload({
        fileName,
        mimeType,
        content,
        emailId: this.emailId,
      })
      .subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.attachmentFiles.forEach(attachment => {
              if (attachment.fileName === fileName) {
                attachment.progress = Math.round(
                  (100 * event.loaded) / event.total
                );
              }
            });
          } else if (event instanceof HttpResponse) {
            if (event.status === 200) {
              this.fileId = event.body.fileId;
              this.attachmentFiles.forEach(attachment => {
                if (attachment.fileName === fileName) {
                  attachment.fileId = event.body.fileId;
                }
              });
              if (this.isNeedToUpdate) {
                this.updateDraft(false);
              }
            } else {
              if (this.isNeedToUpdate) {
                this.attachmentFiles = this.attachmentFiles.filter(
                  item => item.fileName !== fileName
                );
                this.openErrorDialog(
                  'error',
                  `An error occurred while uploading the file: ${fileName}.`
                );
              }
            }
          }
        },
        error => {
          if (this.isNeedToUpdate) {
            this.openErrorDialog(
              'error',
              this.pipeErrors.transform(error.error.code) ||
                `An error occurred while uploading the file: ${fileName}.`,
              fileName
            );
            // this.snackbarHelper.openSnackBar(`An error occurred while downloading the file: ${fileName}.`);
            this.attachmentFiles = this.attachmentFiles.filter(
              item => item.fileName !== fileName
            );
          }
        }
      );

    const cancelRequestIfCloseComponent = setInterval(() => {
      if (!this.isNeedToUpdate) {
        subscription?.unsubscribe();
        clearInterval(cancelRequestIfCloseComponent);
      }
    }, 400);
  }

  async deleteAttachment(file: AttachmentUploadContract): Promise<void> {
    const index = this.attachmentFiles.indexOf(file);
    this.attachmentFiles.splice(index, 1);
  }

  public showNotes(): void {
    this.menuContent = EmailMenuContent.Notes;
    this.menuIsOpened = true;
  }

  public showTemplates(): void {
    this.menuContent = EmailMenuContent.Templates;
    this.menuIsOpened = true;
  }

  public refreshNotes(): void {
    this.isNotesChanged = true;
  }

  public closeMenu(): void {
    this.menuIsOpened = false;
    this.menuContent = null;
  }

  async onSubmit(send: boolean): Promise<void> {
    if (send && !this.validateForm()) {
      return;
    }

    this.submitInProgress = true;
    this.cannotSaveData = false;
    this.drawerService.disabledDrawer(true);

    try {
      await this.updateDraft(false, true);
      if (send) {
        if (this.canSend) {
          await this.emailClient.markForSend(
            new BaseGetByIdRequest(this.email.emailId ?? null)
          );
        } else {
          await this.emailClient.readyToValidate(
            new BaseGetByIdRequest(this.email.emailId ?? null)
          );
        }
        this.isSaveOnClose = false;
        this.deleteFromData.emit(this.email.emailId);
      }
      await this.userWorkspaceService.refreshUnread({
        mailAccountId: this.email.mailAccountId,
      });
      const data = this.tagsEmail();
      data && (await this.emailTagClient.saveTags(data));
      this.submitted.emit();
      this.drawerService.disabledDrawer(false);
      this.drawerService.closeDrawer();
    } catch (e) {
      this.cannotSaveData = true;
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.submitInProgress = false;
    }
  }

  async delete(): Promise<any> {
    try {
      if (!this.emailId) return;
      await this.emailClient.delete({ emailIds: [this.emailId] });
      this.submitted.emit();
      this.isSaveOnClose = false;
      this.drawerService.closeDrawer();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async saveAsDraft(): Promise<any> {
    this.saveInProgress = true;
    try {
      await this.onSubmit(false);
    } catch (e) {
      this.matchError.logError(e);
    } finally {
      this.isSaveOnClose = false;
      this.saveInProgress = false;
    }
  }

  public changeToList(value: string[]): void {
    this.toList = value;
  }

  public changeCcList(value: string[]): void {
    this.ccList = value;
  }
}
