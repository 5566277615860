import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { TagClient } from '@app-services/api/clients/tag.client';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { OrderDirection } from '@app-types/enums/order-direction';
import {
  GetOrganizationMailAccountsRequest,
  MailAccountContract,
} from '@app-types/api/mail-account';
import { SomethingWentWrongComponent } from '../common/error/something-went-wrong/something-went-wrong.component';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCasePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  EMAIL_QUERY_PARAM,
  STORAGE_NAMES,
  WEB_CODE,
} from '@app-shared/constants/constants';
import { LocalStorageService } from '@app-services/local-storage.service';
import { decodeInfoMailAccount } from '@app-utils/search-params';
import { transformEnum } from '@app-utils/common';
import {
  AttachmentsEnum,
  NotificationsEnum,
  ReassignedEnum,
  SearchTypeEnum,
  SeenEnum,
  SourceEnum,
  ValidatedEnum,
} from '@app-types/enums/filters';
import { EmailSearchTagInputComponent } from './email-search-tag-input/email-search-tag-input.component';
import { SearchParams } from '@app-types/search';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

const { SEARCH_TYPE } = STORAGE_NAMES;

@Component({
  selector: 'app-email-search-advanced-dialog',
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    LowerCasePipe,
    SkeletonFormDrawerComponent,
    EmailSearchTagInputComponent,
  ],
  templateUrl: './email-search-advanced-dialog.component.html',
  styleUrls: ['./email-search-advanced-dialog.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: '' },
    },
  ],
})
export class EmailSearchAdvancedDialogComponent implements OnInit {
  @Output() closeEventEmitter = new EventEmitter();
  @Output() searchFilters = new EventEmitter();
  public selectedMailAccountId: number;
  public webCode: string;
  public isLoading = true;
  public cannotLoadData = false;
  public filters: SearchParams = new SearchParams();
  public searchDirections = transformEnum(OrderDirection, 'id', 'name');

  public existedTags: string[] = [];
  public selectedTags = new Set<string>();
  public reassignId: number;
  public seen = transformEnum(SeenEnum);
  public attachments = transformEnum(AttachmentsEnum);
  public source = transformEnum(SourceEnum);
  public notifications = transformEnum(NotificationsEnum);
  public reassigned = transformEnum(ReassignedEnum);
  public validated = transformEnum(ValidatedEnum);
  public searchType = transformEnum(SearchTypeEnum);

  public selectedAttachmentsStatus: AttachmentsEnum;
  public selectedSourceStatus: SourceEnum;
  public selectedHideNotificationsStatus: NotificationsEnum;
  public selectedSeenStatus: SeenEnum;
  public selectedReassignedId: ReassignedEnum;
  public selectedReassignedStatus: ReassignedEnum;
  // TODO
  public selectedValidationStatus: any;
  public accounts: MailAccountContract[] = [];
  public selectedSearchType: number = 1;

  public filterForm: UntypedFormGroup = new UntypedFormGroup({
    sentStart: new UntypedFormControl(),
    sentEnd: new UntypedFormControl(),
    downloadStart: new UntypedFormControl(),
    downloadEnd: new UntypedFormControl(),
  });

  constructor(
    public matchError: MatchError,
    private tagClient: TagClient,
    private mailAccountClient: MailAccountClient,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
    if (window.location.search) {
      const newUrl = new URL(window.location.href);
      if (newUrl.pathname.includes('emails')) {
        const search: string = newUrl.searchParams.get(EMAIL_QUERY_PARAM) ?? '';
        const data = decodeInfoMailAccount(search);
        data?.acc && (this.selectedMailAccountId = data.acc as number);
        data?.filter && (this.filters = data.filter as SearchParams);
      }
    }
    if (this.filters.reassignedId === null) {
      this.selectedReassignedId = 0;
    } else {
      this.selectedReassignedId = this.filters.reassignedId;
    }
    this.selectedSearchType =
      this.localStorageService.getData(SEARCH_TYPE) || 1;
    if (this.filters.reassigned === null) {
      this.selectedReassignedStatus = ReassignedEnum.all;
    } else if (!this.filters.reassigned) {
      this.selectedReassignedStatus = ReassignedEnum['not reassigned'];
    } else {
      this.selectedReassignedStatus = ReassignedEnum.reassigned;
    }
    if (this.filters.seen === null) {
      this.selectedSeenStatus = SeenEnum.showAll;
    } else if (!this.filters.seen) {
      this.selectedSeenStatus = SeenEnum.onlyUnseen;
    } else {
      this.selectedSeenStatus = SeenEnum.onlySeen;
    }
    if (this.filters.withAttachments === null) {
      this.selectedAttachmentsStatus = AttachmentsEnum.showAll;
    } else if (!this.filters.withAttachments) {
      this.selectedAttachmentsStatus = AttachmentsEnum.onlyWithoutAttachments;
    } else {
      this.selectedAttachmentsStatus = AttachmentsEnum.onlyWithAttachments;
    }
    if (this.filters.source === null) {
      this.selectedSourceStatus = SourceEnum.showAll;
    } else if (!this.filters.source) {
      this.selectedSourceStatus = SourceEnum.mailboxPEC;
    } else {
      this.selectedSourceStatus = SourceEnum.reassignment;
    }
    if (!this.filters.hideNotifications) {
      this.selectedHideNotificationsStatus = NotificationsEnum.showAll;
    } else {
      this.selectedHideNotificationsStatus =
        NotificationsEnum['Only without notifications'];
    }

    this.filterForm.setValue({
      sentStart: this.filters.sentDateTime.start || null,
      sentEnd: this.filters.sentDateTime.end
        ? this.substractDays(this.filters.sentDateTime.end, 1)
        : null,
      downloadStart: this.filters.downloadDateTime.start || null,
      downloadEnd: this.filters.downloadDateTime.end || null,
    });

    this.selectedMailAccountId && (await this.loadData());
    !this.selectedMailAccountId && (this.isLoading = false);
  }

  onSearchTypeChange(value: number): void {
    this.localStorageService.setData(SEARCH_TYPE, value);
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  substractDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  async loadData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadData = false;
    try {
      const tagResponse = await this.tagClient.getForMailAccount(
        new BaseGetByIdRequest(this.selectedMailAccountId)
      );
      this.existedTags = tagResponse.data.map(e => e.tag);
      this.selectedTags = new Set(this.filters.tags);

      const mailAccountRequest =
        await this.mailAccountClient.getOrganizationMailAccounts(
          new GetOrganizationMailAccountsRequest(this.webCode)
        );
      this.accounts = mailAccountRequest.data;
    } catch (e) {
      this.cannotLoadData = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async search(): Promise<void> {
    this.selectedReassignedId === ReassignedEnum.all
      ? (this.filters.reassignedId = null)
      : (this.filters.reassignedId = this.selectedReassignedId);
    this.filters.sentDateTime.start = this.filterForm.controls.sentStart?.value;
    this.filters.sentDateTime.end =
      this.filterForm.controls.sentEnd?.value &&
      this.addDays(this.filterForm.controls.sentEnd?.value, 1);
    this.filters.downloadDateTime.start =
      this.filterForm.controls.downloadStart?.value;
    this.filters.downloadDateTime.end =
      this.filterForm.controls.downloadEnd?.value;

    this.filters.validated =
      this.selectedValidationStatus &&
      this.selectedValidationStatus !== ValidatedEnum.all
        ? [this.selectedValidationStatus]
        : null;

    this.filters.source =
      this.selectedSourceStatus === SourceEnum.showAll
        ? null
        : this.selectedSourceStatus !== SourceEnum.mailboxPEC;

    this.filters.reassigned =
      this.selectedReassignedStatus === ReassignedEnum.all
        ? null
        : this.selectedReassignedStatus !== ReassignedEnum['not reassigned'];

    this.filters.seen =
      this.selectedSeenStatus === SeenEnum.showAll
        ? null
        : this.selectedSeenStatus !== SeenEnum.onlyUnseen;

    this.filters.withAttachments =
      this.selectedAttachmentsStatus === AttachmentsEnum.showAll
        ? null
        : this.selectedAttachmentsStatus !==
          AttachmentsEnum.onlyWithoutAttachments;

    this.filters.hideNotifications =
      this.selectedHideNotificationsStatus !== NotificationsEnum.showAll;

    this.filters.tags = Array.from(this.selectedTags);

    this.searchFilters.emit({
      filter: this.filters,
      searchType: this.selectedSearchType,
    });
  }

  resetParams(): void {
    this.selectedSearchType = 1;
    this.selectedReassignedId = ReassignedEnum.all;
    this.selectedAttachmentsStatus = AttachmentsEnum.showAll;
    this.selectedSeenStatus = SeenEnum.showAll;
    this.selectedSourceStatus = SourceEnum.showAll;
    this.selectedTags = new Set<string>();
    this.filterForm.setValue({
      sentStart: null,
      sentEnd: null,
      downloadStart: null,
      downloadEnd: null,
    });
    this.filters = new SearchParams();
    this.searchFilters.emit();
  }
}
