import { RoleType } from '@app-types/enums/role-type';
import { FilterFields, Roles } from '@app-types/common';

export const LOCALE_IDS = [
  {
    language: 'English',
    id: 'en',
  },
  {
    language: 'Deutsch',
    id: 'de',
  },
  {
    language: 'Italiano',
    id: 'it',
  },
  {
    language: 'Français',
    id: 'fr',
  },
];

export const MENU_STATE = [
  {
    title: 'active',
    field: 'isActive',
    value: true,
    fieldRemove: 'isConfigurationCompleted',
  },
  {
    title: 'notActive',
    field: 'isActive',
    value: false,
    fieldRemove: 'isConfigurationCompleted',
  },
  {
    title: 'notConfigured',
    field: 'isConfigurationCompleted',
    value: false,
    fieldRemove: 'isActive',
  },
];

export const WEB_CODE = 'webCode';
export const ORGANIZATION_ID = 'organizationId';
export const MAIL_ACCOUNT_ID = 'mailAccountId';

export const STORAGE_NAMES = {
  CLIENT_ID: 'clientId',
  PRE_RELEASE: 'preRelease',
  USER_LOCALE: 'userLocale',
  ORGANISATIONS: 'organizations',
  AUTH_TYPE: 'authType',
  SEARCH_TYPE: 'searchType',
  PAGE_SIZE: 'pageSize',
  PAGE_SIZE_SETTINGS: 'pageSizeSettings',
  SHOW_ADVANCED_SETTINGS: 'showAdvancedSettings',
};

export const USER_ROLE = {
  RTA: 'RTA',
  ROOT: 'Root',
  USER: 'Default',
};

export const EMAIL_QUERY_PARAM = 'mail';

export const ROLES: Roles[] = [
  {
    name: 'User',
    value: RoleType.User,
  },
  {
    name: 'SuperUser',
    value: RoleType.SuperUser,
  },
  {
    name: 'Creator',
    value: RoleType.Creator,
  },
  {
    name: 'Validator',
    value: RoleType.Validator,
  },
  {
    name: 'LimitedUser',
    value: RoleType.LimitedUser,
  },
  {
    name: 'LimitedCreator',
    value: RoleType.LimitedCreator,
  },
];

export const defaultFilterFields: FilterFields = {
  search: null,
  ProviderId: null,
  isActive: null,
  isArchive: null,
  searchOrder: 1,
  orderDirection: 1,
};

export const SHOWING_CONDITIONS = [
  { header: 'subject', paramName: 'subject' },
  { header: 'from', paramName: 'from' },
  { header: 'to', paramName: 'to' },
  { header: 'cc', paramName: 'cc' },
  { header: 'bcc', paramName: 'bcc' },
  { header: 'body', paramName: 'body' },
];

export const ListOfPossibleLanguages = ['en', 'de', 'it', 'fr'];

export const PATH_ARCHIVE_FTP = 'TX\\PECMAN\\CONS';
