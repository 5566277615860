import { Component, Input, OnInit } from '@angular/core';
import { EmailAuditContract } from '@app-types/api/emails/email-audit';
import { BaseGetCollectionByIdRequest } from '@app-types/base/base';
import { EmailAuditClient } from 'src/app/services/api/clients/email-audit-client';
import { EmailAuditEventType } from 'src/app/types/enums/email-audit-event-type';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SomethingWentWrongComponent } from '../../error/something-went-wrong/something-went-wrong.component';
import { DatePipe } from '@angular/common';
import { EmailAuditEventTypePipe } from '@app-pipes/email-audit-event-type.pipe';
import { isEventMetadata, isJSON } from '@app-utils/common';
import { SkeletonCardListComponent } from '@app-components/common/skeletons/skeleton-card-list/skeleton-card-list.component';
import { TYPE_ARCHIVE } from '@app-shared/constants/archive';

@Component({
  selector: 'app-email-audit',
  templateUrl: './email-audit.component.html',
  styleUrls: ['./email-audit.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    DatePipe,
    EmailAuditEventTypePipe,
    SkeletonCardListComponent,
  ],
})
export class EmailAuditComponent implements OnInit {
  @Input() emailId: number;
  @Input() title: string;

  public isAuditLoadingError: boolean;
  public isAuditLoading: boolean;
  public events: EmailAuditContract[] = [];

  constructor(
    private emailAuditClient: EmailAuditClient,
    private translate: TranslateService
  ) {}

  async ngOnInit(): Promise<any> {
    this.isAuditLoadingError = false;
    this.isAuditLoading = true;
    try {
      const response = await this.emailAuditClient.getByEmail(
        new BaseGetCollectionByIdRequest(this.emailId, 1, 100)
      );
      this.events = response.data;
      this.localizeMetadata(this.events);
    } catch (err) {
      console.log(err);
      this.isAuditLoadingError = true;
    } finally {
      this.isAuditLoading = false;
    }
  }

  localizeMetadata(events: EmailAuditContract[]): void {
    let str = '';
    events.map((event: EmailAuditContract) => {
      if (isJSON(event.metadata)) {
        if (isEventMetadata(JSON.parse(event.metadata))) {
          const temp = JSON.parse(event.metadata);

          this.translate
            .stream(temp['Label'] || ' ')
            .subscribe((res: string) => {
              str = res;
            });
          str = this.argsConfig(str, temp['Args'], temp['Label']);
          event.metadata = str;
        }
      }
    });
  }

  argsConfig(str: string, args: string[], flag: string) {
    switch (flag) {
      case 'EmailAuditInformationForwardReplyEvent': {
        args.forEach((item, index) => {
          str = str.replace(`{${index}}`, '');
          if (item) {
            str = str + item;
            if (args[index + 1]) str = str + '; ';
          }
        });
        return str;
      }
      case 'EmailAuditArchiveEvent': {
        args.forEach((item, index) => {
          str = str.replace(`{${index}}`, TYPE_ARCHIVE[item]);
        });
        return str;
      }
      default: {
        args.forEach((item, index) => {
          str = str.replace(`{${index}}`, item);
        });
        return str;
      }
    }
  }

  public isAuditWithError(event: EmailAuditContract): boolean {
    return (
      event.type === EmailAuditEventType.DispatchigRuleActionError ||
      event.type === EmailAuditEventType.VirusDetected ||
      event.type === EmailAuditEventType.SentError
    );
  }
}
