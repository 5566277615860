import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private zone: NgZone,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) {}

  public openSnackBar(
    message: string,
    action = 'OK',
    config?: MatSnackBarConfig
  ): void {
    this.zone.run(() => {
      let translateMessage = message;
      this.translate.stream(message || ' ').subscribe((res: string) => {
        translateMessage = res;
      });
      const snackBar = this.snackBar.open(
        translateMessage,
        action,
        config
          ? config
          : {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: 5000,
            }
      );
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      });
    });
  }
}
