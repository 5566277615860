export const removeCommentHtml = (body: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(body, 'text/html');
  const comments = doc.createTreeWalker(doc, NodeFilter.SHOW_COMMENT, null);
  let commentNode = comments.nextNode();
  while (commentNode) {
    const nextComment = comments.nextNode();
    commentNode.parentNode?.removeChild(commentNode);
    commentNode = nextComment;
  }
  return doc.body.innerHTML;
};
