import { Injectable } from '@angular/core';
import { META_ERRORS } from '@app-shared/constants/meta';
import { LoggingHandler } from '@app-services/errors/logging.service';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private loggingHandler: LoggingHandler) {}

  sendNewMessage(originalResult: string): void {
    this.loggingHandler.create(
      'information',
      'New meta error message',
      originalResult
    );
  }

  getText(message: string, originalResult: string): string {
    const text =
      META_ERRORS[message] ??
      Object.entries(META_ERRORS).find(([key, _]) =>
        message.toLowerCase().includes(key.toLowerCase())
      )?.[1] ??
      message;
    if (message === text) {
      this.sendNewMessage(originalResult);
    }
    return text;
  }
}
