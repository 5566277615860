<div class="drawer-content-screen">
  @if(settingsIsLoading) {
    <div class="h-full">
     <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(failedSettingsLoading) {
    <div class="flex-col-center">
      <app-something-went-wrong
        [showPicture]="false"
        description="{{ 'cannotLoadCompanySettings' | translate }}"
        (retryEmitter)="failedUpdate ? onSubmit() : loadSettings()">
      </app-something-went-wrong>
    </div>
  }

  @if(!settingsIsLoading && !failedSettingsLoading && isUploaded) {
    <div class="drawer-content-screen__title">
      {{ "companySettings" | translate }}
    </div>
  <div class="overflow-auto flex flex-col gap-3 h-full pr-2">
    <form
      class="flex-col-container-1"
      [formGroup]="form"
      autocomplete="off"
      novalidate
      (ngSubmit)="onSubmit()"
    >
      <div class="drawer-content-screen__sub-title">{{ "archive" | translate }}</div>
      <mat-radio-group color="primary" formControlName="archive">
        <mat-radio-button [value]="ArchiveType.None">{{ "archivingNone" | translate }}</mat-radio-button>
        <mat-radio-button [value]="ArchiveType.Pec2Cons">{{ "archivingSettings" | translate }}</mat-radio-button>
        <mat-radio-button [value]="ArchiveType.Ftp">{{ "archivingFtp" | translate }}</mat-radio-button>
      </mat-radio-group>
      @if(form.value.archive === ArchiveType.Ftp) {
        <mat-form-field>
          <mat-label>{{ "archivingDestination" | translate }}</mat-label>
          <input
            matInput
            [readonly]="true"
            [value]="pathFtp"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "batchSize" | translate }}</mat-label>
          <input
            type="number"
            min="0"
            max="200"
            matInput
            id="countFtp"
            formControlName="countFtp"
          />
          @if(hasError('countFtp', 'pattern')) {
            <mat-error>Must be only integers</mat-error>
          }
          @if(hasError('countFtp', 'max')) {
             <mat-error>{{ "lessThan200" | translate }}</mat-error>
          }
        </mat-form-field>
        }
        <div class="flex flex-col w-full">
          @if(form.value.archive === ArchiveType.Pec2Cons) {
            <mat-form-field>
              <mat-label>{{ "prefix" | translate }}</mat-label>
              <input
                matInput
                maxlength="1"
                id="prefix"
                formControlName="prefix"
              />
              @if(hasError('prefix', 'maxlength')) {
                <mat-error>{{ "moreThanOneChar" | translate }}</mat-error>
              }
              @if(hasError('prefix', 'required')) {
                <mat-error>{{ "prefixIsRequired" | translate }}</mat-error>
              }
            </mat-form-field>
            <div class="flex gap-2 sm:gap-5 w-full">
              <mat-form-field class="w-full">
                <mat-label>{{ "outFlowPostfix" | translate }}</mat-label>
                <input
                  maxlength="1"
                  matInput
                  id="postfixOutFlow"
                  formControlName="postfixOutFlow"
                />
                @if(hasError('postfixOutFlow', 'maxlength')) {
                  <mat-error>{{ "moreThanOneChar" | translate }}</mat-error>
                }
                @if(hasError('postfixOutFlow', 'required')) {
                  <mat-error>{{ "outFlowPostfixIsRequired" | translate }}</mat-error>
                }
              </mat-form-field>
              <mat-form-field class="w-full">
                <mat-label>{{ "inFlowPostfix" | translate }}</mat-label>
                <input
                  maxlength="1"
                  matInput
                  id="postfixInFlow"
                  formControlName="postfixInFlow"
                />
                @if(hasError('postfixInFlow', 'maxlength')) {
                  <mat-error>{{ "moreThanOneChar" | translate }}</mat-error>
                }
                @if(hasError('postfixInFlow', 'required')) {
                  <mat-error>{{ "inFlowPostfixIsRequired" | translate }}</mat-error>
                }
              </mat-form-field>
            </div>
            <mat-form-field>
              <mat-label>{{ "batchSize" | translate }}</mat-label>
              <input
                type="number"
                min="0"
                max="200"
                matInput
                id="count"
                formControlName="count"
              />
              @if(hasError('count', 'pattern')) {
                <mat-error>Must be only integers</mat-error>
              }
              @if(hasError('count', 'max')) {
                <mat-error>{{ "lessThan200" | translate }}</mat-error>
              }
            </mat-form-field>
          }
        </div>
      <div class="flex-col-container-1 mt-3">
        <div class="drawer-content-screen__sub-title">{{ "authSettings" | translate }}</div>
        <div class="flex flex-col">
          <mat-checkbox
            [disabled]="settings.credemISAMSupport"
            color="primary"
            [(ngModel)]="settings.oidcSupport"
            [ngModelOptions]="{standalone: true}"
          >{{ "GAW" | translate }}</mat-checkbox>
          <mat-checkbox
            [disabled]="settings.credemISAMSupport"
            color="primary"
            [(ngModel)]="settings.googleOAuthSupport"
            [ngModelOptions]="{standalone: true}"
            [disableRipple]="true"
          >{{ "google" | translate }}</mat-checkbox>
          <mat-checkbox
            color="primary"
            (change)="onSamlChange($event.checked)"
            [(ngModel)]="settings.credemISAMSupport"
            [ngModelOptions]="{standalone: true}"
            [disableRipple]="true"
          >{{ "oidcCredem" | translate }}</mat-checkbox>
        </div>
        <mat-form-field>
          <mat-label>{{ "oidcCredemRtaClaim" | translate }}</mat-label>
          <input
            matInput
            id="samlRtaClaim"
            formControlName="samlRtaClaim"
          />
          @if(hasError('oidcCredemRtaClaim', 'required')) {
            <mat-error>{{ "oidcCredemRtaClaimIsRequired" | translate }}</mat-error>
          }
          @if(hasError('oidcCredemRtaClaim', 'maxlength')) {
            <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
          }
        </mat-form-field>
        @if(!authTypeIsSelected) {
          <div class="mt-2">
            <app-error-hint
              errorText="{{ 'selectAtLeastOneTypeOfAuthenticationErrorHint' | translate }}"
              [centerAlign]="false"
            ></app-error-hint>
          </div>
        }
      </div>
    </form>
    <div class="notification-lang">
      <div class="drawer-content-screen__sub-title">{{ "defaultNotificationLanguage" | translate }}</div>
      <div class="notification-lang__body">
        <mat-form-field>
          <mat-label>{{ "language" | translate }}</mat-label>
          <mat-select id="locale" [formControl]="locale">
            @for(item of locales; track item) {
              @if(item.id !== 'fr' && item.id !== 'de') {
                <mat-option [value]="item.id">{{item.language}}</mat-option>
              }
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="space-alert">
      <div class="space-alert__body">
        <div class="drawer-content-screen__sub-title">{{ "alertOccupiedSpace" | translate }}</div>
        <div class="space-alert__line">
          <span> {{ "totalSpaceOccupiesPerCompany" | translate }}</span>
          <div class="space-alert__plan-cell space-alert__cell">
            <mat-form-field class="space-alert__mat-form-field">
              <mat-label>{{ "spacePaymentPlan" | translate }}</mat-label>
              <mat-select id="spaceUsedPaymentPlan" [formControl]="spaceUsedPaymentPlan">
                @for(item of spaceUsedPaymentPlanType; track item) {
                  <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="space-alert__line flex-wrap xs:flex-nowrap">
            <p> {{ "definitionForAvailableSpaceWarning" | translate }}</p>
            <div class="inline-block whitespace-nowrap mx-[auto] xs:mx-0">
              <mat-form-field class="space-alert__number-input space-alert__cell input-number">
                <input
                  type="number"
                  matInput
                  [(ngModel)]="settings.alertLevelSpaceUsed"
                  [ngModelOptions]="{standalone: true}"
                  step="1"
                  min="0"
                  [max]="100"
                  (change)="proccessValueSpace()"
                  id="myNumber"
                />
              </mat-form-field>
              %
            </div>
          </div>
          <div class="space-alert__subtext mt-1 xs:mt-0">
            {{"thresholdForExtraGB" | translate}}
          </div>
        </div>
        <div>
          <div class="flex items-baseline">
            <mat-checkbox
              class="mat-checkbox-inner-container"
              color="primary"
              [(ngModel)]="settings.alertSpaceUsed"
              [ngModelOptions]="{ standalone: true }"
            ></mat-checkbox>
              <div class="flex flex-col">
                <div>
                  {{"thresholdAlerting" | translate}}
                </div>
                <div class="space-alert__subtext">
                  {{"emailAlertToRTA" | translate}}
                </div>
              </div>
          </div>
        </div>
      </div>
      <form class="auto-delete" [formGroup]="autoDeleteForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <div class="drawer-content-screen__sub-title">{{ "finalDeletionTitle" | translate }}</div>
        <div class="flex-col-container-1">
          <div class="auto-delete__body relative">
            <mat-checkbox
              class="mat-checkbox-inner-container"
              color="primary"
              formControlName="deleteEmailsFromTrash"
            ></mat-checkbox>
            {{"empty" | translate}}
            <b>{{"trashUpperCase" | translate}}</b>
            {{"folderAfter" | translate}}
            <mat-form-field
              class="auto-delete__number-input input-number"
              [class.auto-delete__number-input-wrapper]="autoDeleteForm.controls['delayDeleteEmailsFromTrash'].valid">
              <input
                class="mb-0"
                type="number"
                matInput
                formControlName="delayDeleteEmailsFromTrash"
                (input)="proccessValueDays($event, 'delayDeleteEmailsFromTrash')"
                step="1"
                min="0"
              />
            </mat-form-field>
            <span>{{"days" | translate}}</span>
            @if(autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors
            && autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors['required']) {
              <mat-error>{{ "fieldRequired" | translate }}</mat-error>
            }
            @if(autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors
            && !autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors['required']
            && autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors['lowerLimit']) {
              <mat-error>{{ "valueLess" | translate }}</mat-error>
            }
            @if(autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors
            && autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors['higherLimit']) {
              <mat-error>{{ "valueMore" | translate }}</mat-error>
            }
          </div>
          <div class="auto-delete__body relative">
            <mat-checkbox
              class="mat-checkbox-inner-container"
              color="primary"
              formControlName="deleteEmailsFromSpam"
            ></mat-checkbox>
            {{"empty" | translate}}
            <b>{{"spamUpperCase" | translate}}</b>
            {{"folderAfter" | translate}}
            <mat-form-field
              class="auto-delete__number-input input-number"
              [class.auto-delete__number-input-wrapper]="autoDeleteForm.controls['delayDeleteEmailsFromSpam'].valid">
              <input
                class="mb-0"
                type="number"
                matInput
                formControlName="delayDeleteEmailsFromSpam"
                (input)="proccessValueDays($event, 'delayDeleteEmailsFromSpam')"
                step="1"
                min="0"
              />
            </mat-form-field>
            <span>{{"days" | translate}}</span>
          </div>
          @if(autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors
            && autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors['required']) {
            <mat-error>{{ "fieldRequired" | translate }}</mat-error>
          }
          @if(autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors
          && !autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors['required']
          && autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors['lowerLimit']) {
            <mat-error> {{ "valueLess" | translate }}</mat-error>
          }
          @if(autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors
          && autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors['higherLimit']) {
            <mat-error>{{ "valueMore" | translate }}</mat-error>
          }
        </div>
      </form>
    </div>
  </div>
  <div class="flex justify-end">
    <button
      mat-flat-button
      color="primary"
      [disabled]="isLoading || !form.valid || !autoDeleteForm.valid || !authTypeIsSelected"
      (click)="isAuthMethodsWasChanged() ? openConfirmDialog() : onSubmit()">
      <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
}
</div>
