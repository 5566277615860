import { Component, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { OrganizationSettingsClient } from '@app-services/api/clients/organization-settings.client';
import { OrganizationSettingsContract } from '@app-types/api/Settings';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MatDialog } from '@angular/material/dialog';
import { ClaimClient } from 'src/app/services/api/clients/claim';
import { SamlClaims } from 'src/app/types/api/mail-account';
import { ConfirmDialogComponent } from 'src/app/components/common/confirm-dialog/confirm-dialog.component';
import { RoleType } from 'src/app/types/enums/role-type';
import {
  LOCALE_IDS,
  PATH_ARCHIVE_FTP,
} from 'src/app/shared/constants/constants';
import {
  outMaxLimit,
  outMinLimit,
} from 'src/app/shared/utils/custom-validator';
import { LoadingButtonComponent } from '../../../common/loading-button/loading-button.component';
import { SomethingWentWrongComponent } from '../../../common/error/something-went-wrong/something-went-wrong.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorHintComponent } from '../../../common/error-hint/error-hint.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { DrawerService } from '@app-services/drawer.service';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import {
  SpaceUsedPaymentPlanDetailTypes,
  SpaceUsedPaymentPlanSelectTypes,
} from '@app-shared/constants/space-used';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';
import { MatRadioModule } from '@angular/material/radio';
import { ArchiveType } from '@app-types/enums/archive';

@Component({
  selector: 'app-organization-settings-dialog',
  templateUrl: './organization-settings-dialog.component.html',
  styleUrls: ['./organization-settings-dialog.component.scss'],
  standalone: true,
  imports: [
    LoadingButtonComponent,
    SkeletonFormDrawerComponent,
    SomethingWentWrongComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatInputModule,
    TranslateModule,
    ErrorHintComponent,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
  ],
})
export class OrganizationSettingsDialogComponent implements OnInit {
  @Input() organizationId: number;
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public autoDeleteForm: UntypedFormGroup = new UntypedFormGroup({});
  public settings: OrganizationSettingsContract;
  public settingsIsLoading = false;
  public isUploaded = false;
  public failedSettingsLoading = false;
  public samlClaims: SamlClaims;
  public isLoading = false;
  public failedUpdate = false;
  public initAuthMethods: boolean[] = [];
  public locales = LOCALE_IDS;
  public locale: UntypedFormControl = new UntypedFormControl('');
  public spaceUsedPaymentPlan: UntypedFormControl = new UntypedFormControl(1);
  public spaceUsedPaymentPlanType = SpaceUsedPaymentPlanSelectTypes;
  public ArchiveType = ArchiveType;
  public pathFtp = PATH_ARCHIVE_FTP;

  constructor(
    private organizationSettingsClient: OrganizationSettingsClient,
    public claimClient: ClaimClient,
    public matchError: MatchError,
    public dialog: MatDialog,
    private drawerService: DrawerService,
    private userWorkspaceService: UserWorkspaceService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadSettings();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      archive: new UntypedFormControl(
        this.settings.archive
          ? (this.settings?.archiveType ?? ArchiveType.None)
          : ArchiveType.None
      ),
      licenseNumber: new UntypedFormControl(
        this.settings.archiveSettings?.archiveSettingsPec2Cons?.licenseNumber ??
          ''
      ),
      licenseCode: new UntypedFormControl(
        this.settings.archiveSettings?.archiveSettingsPec2Cons?.licenseCode ??
          ''
      ),
      prefix: new UntypedFormControl(
        this.settings.archiveSettings?.archiveSettingsPec2Cons?.prefix ?? '',
        [Validators.maxLength(1)]
      ),
      postfixInFlow: new UntypedFormControl(
        this.settings.archiveSettings?.archiveSettingsPec2Cons?.postfixInFlow ??
          '',
        [Validators.maxLength(1)]
      ),
      postfixOutFlow: new UntypedFormControl(
        this.settings.archiveSettings?.archiveSettingsPec2Cons
          ?.postfixOutFlow ?? '',
        [Validators.maxLength(1)]
      ),
      count: new UntypedFormControl(
        this.settings.archiveSettings?.archiveSettingsPec2Cons?.count ?? '',
        [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(200)]
      ),
      countFtp: new UntypedFormControl(
        this.settings.archiveSettings?.archiveSettingsFtp?.count ?? '',
        [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(200)]
      ),
      samlRtaClaim: new UntypedFormControl(
        this.samlClaims?.claimCode || '',
        []
      ),
    });

    const archive = this.form?.get('archive');

    archive &&
      archive.valueChanges.subscribe(selectedValue => {
        this.form.controls.prefix.setValidators(
          selectedValue === ArchiveType.Pec2Cons
            ? [Validators.required, Validators.maxLength(1)]
            : [Validators.maxLength(1)]
        );
        this.form.controls.postfixInFlow.setValidators(
          selectedValue === ArchiveType.Pec2Cons
            ? [Validators.required, Validators.maxLength(1)]
            : [Validators.maxLength(1)]
        );
        this.form.controls.postfixOutFlow.setValidators(
          selectedValue === ArchiveType.Pec2Cons
            ? [Validators.required, Validators.maxLength(1)]
            : [Validators.maxLength(1)]
        );
        this.form.controls.prefix.markAsTouched();
        this.form.controls.postfixInFlow.markAsTouched();
        this.form.controls.postfixOutFlow.markAsTouched();
        this.form.controls.prefix.updateValueAndValidity();
        this.form.controls.postfixInFlow.updateValueAndValidity();
        this.form.controls.postfixOutFlow.updateValueAndValidity();
      });

    if (this.settings.archiveType === ArchiveType.Pec2Cons) {
      this.form.controls.prefix.setValidators([
        Validators.required,
        Validators.maxLength(1),
      ]);
      this.form.controls.postfixInFlow.setValidators([
        Validators.required,
        Validators.maxLength(1),
      ]);
      this.form.controls.postfixOutFlow.setValidators([
        Validators.required,
        Validators.maxLength(1),
      ]);
    } else {
      this.form.controls.prefix.setValidators([Validators.maxLength(1)]);
      this.form.controls.postfixInFlow.setValidators([Validators.maxLength(1)]);
      this.form.controls.postfixOutFlow.setValidators([
        Validators.maxLength(1),
      ]);
    }
    if (this.settings.credemISAMSupport) {
      this.form.controls.samlRtaClaim.setValidators([
        Validators.required,
        Validators.maxLength(256),
      ]);
    } else {
      this.form.controls.samlRtaClaim.setValidators([]);
      this.form.controls.samlRtaClaim.disable();
    }

    this.form.controls.samlRtaClaim.updateValueAndValidity();

    this.autoDeleteForm = new UntypedFormGroup({
      deleteEmailsFromTrash: new UntypedFormControl(
        this.settings.deleteEmailsFromTrash ?? false
      ),
      delayDeleteEmailsFromTrash: new UntypedFormControl(
        this.settings.delayDeleteEmailsFromTrash ?? null,
        this.settings.deleteEmailsFromTrash
          ? [Validators.required, outMinLimit(30), outMaxLimit(90)]
          : []
      ),
      deleteEmailsFromSpam: new UntypedFormControl(
        this.settings.deleteEmailsFromSpam ?? false
      ),
      delayDeleteEmailsFromSpam: new UntypedFormControl(
        this.settings.delayDeleteEmailsFromSpam ?? null,
        this.settings.deleteEmailsFromSpam
          ? [Validators.required, outMinLimit(30), outMaxLimit(90)]
          : []
      ),
    });

    const deleteEmailsFromTrash = this.autoDeleteForm?.get(
      'deleteEmailsFromTrash'
    );
    deleteEmailsFromTrash &&
      deleteEmailsFromTrash.valueChanges.subscribe(selectedValue => {
        this.autoDeleteForm.controls.delayDeleteEmailsFromTrash.setValidators(
          selectedValue
            ? [Validators.required, outMinLimit(30), outMaxLimit(90)]
            : []
        );
        this.autoDeleteForm.controls.delayDeleteEmailsFromTrash.markAsTouched();
        this.autoDeleteForm.controls.delayDeleteEmailsFromTrash.updateValueAndValidity();
      });

    const deleteEmailsFromSpam = this.autoDeleteForm?.get(
      'deleteEmailsFromSpam'
    );
    deleteEmailsFromSpam &&
      deleteEmailsFromSpam.valueChanges.subscribe(selectedValue => {
        this.autoDeleteForm.controls.delayDeleteEmailsFromSpam.setValidators(
          selectedValue
            ? [Validators.required, outMinLimit(30), outMaxLimit(90)]
            : []
        );
        this.autoDeleteForm.controls.delayDeleteEmailsFromSpam.markAsTouched();
        this.autoDeleteForm.controls.delayDeleteEmailsFromSpam.updateValueAndValidity();
      });
  }

  public openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'confirmation',
        isDontNeedConfirmWord: true,
        name: 'changeAuthMethodInfoPopupMessage',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onSubmit();
      }
    });
  }

  public onSamlChange(isChecked: boolean): void {
    if (isChecked) {
      this.form.controls.samlRtaClaim.setValidators([
        Validators.required,
        Validators.maxLength(256),
      ]);
      this.form.controls.samlRtaClaim.enable();
      this.settings.googleOAuthSupport = false;
      this.settings.oidcSupport = false;
    } else {
      this.form.controls.samlRtaClaim.disable();
      this.form.controls.samlRtaClaim.setValidators([]);
    }
    this.form.controls.samlRtaClaim.markAsTouched();
    this.form.controls.samlRtaClaim.updateValueAndValidity();
  }

  public async loadSettings(): Promise<void> {
    this.failedSettingsLoading = false;
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.settingsIsLoading = true;
    }, 500);

    try {
      const organizationClaimRespone =
        await this.claimClient.getByIdRtaClaimByOrganizationId(
          new BaseGetByIdRequest(this.organizationId)
        );
      this.samlClaims = organizationClaimRespone.result;

      const response = await this.organizationSettingsClient.getByOrganization(
        new BaseGetByIdRequest(this.organizationId)
      );
      this.settings = response.result;

      this.settings = {
        ...(this.settings ?? {}),
        alertLevelSpaceUsed: this.settings?.alertLevelSpaceUsed ?? 90,
      };

      if (this.settings.defaultNotificationLanguage) {
        this.locale.setValue(this.settings?.defaultNotificationLanguage ?? '');
      } else {
        this.locale.setValue(null);
      }
      if (this.settings.spaceUsedPaymentPlanType) {
        this.spaceUsedPaymentPlan.setValue(
          this.settings.spaceUsedPaymentPlanType
        );
      } else {
        this.spaceUsedPaymentPlan.setValue(null);
      }
      this.spaceUsedPaymentPlan.disable();
      if (!this.settings.adfsOAuthOptions) {
        this.settings.adfsOAuthOptions = {
          clientId: '',
          authority: '',
          clientSecret: '',
          redirectUri: '',
        };
      }
      if (!this.settings.archiveSettings) {
        this.settings.archiveSettings = {
          archiveSettingsFtp: {},
          archiveSettingsPec2Cons: {
            licenseNumber: '',
            licenseCode: '',
            prefix: '',
            postfixInFlow: '',
            postfixOutFlow: '',
          },
        };
      }

      this.initAuthMethods = [
        this.settings.adfsSupport,
        this.settings.googleOAuthSupport,
        this.settings.oidcSupport,
        this.settings.credemISAMSupport,
      ];

      this.initForm();
    } catch (e) {
      this.failedSettingsLoading = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.settingsIsLoading = false;
    }
  }

  isAuthMethodsWasChanged(): boolean {
    return (
      this.initAuthMethods[0] !== this.settings.adfsSupport ||
      this.initAuthMethods[1] !== this.settings.googleOAuthSupport ||
      this.initAuthMethods[2] !== this.settings.oidcSupport ||
      this.initAuthMethods[3] !== this.settings.credemISAMSupport
    );
  }

  async onSubmit(): Promise<any> {
    if (this.form.valid && this.autoDeleteForm.valid) {
      this.isLoading = true;
      this.failedUpdate = false;
      const newSettings = {
        archiveSettingsFtp: {
          count: this.form.controls.countFtp.value || null,
        },
        archiveSettingsPec2Cons: {
          licenseNumber: this.form.controls.licenseNumber.value || null,
          licenseCode: this.form.controls.licenseCode.value || null,
          prefix: this.form.controls.prefix.value || null,
          postfixInFlow: this.form.controls.postfixInFlow.value || null,
          postfixOutFlow: this.form.controls.postfixOutFlow.value || null,
          count: this.form.controls.count.value || null,
        },
      };

      const updateRequest = {
        archive: this.form.controls.archive.value !== ArchiveType.None,
        archiveType: this.form.controls.archive.value,
        organizationId: this.organizationId,
        archiveSettings: newSettings,
        defaultNotificationLanguage: this.locale.value,
        spaceUsedPaymentPlanType: this.spaceUsedPaymentPlan.value,
        delayDeleteEmailsFromSpam:
          this.autoDeleteForm.controls.delayDeleteEmailsFromSpam.value,
        delayDeleteEmailsFromTrash:
          this.autoDeleteForm.controls.delayDeleteEmailsFromTrash.value,
        deleteEmailsFromSpam:
          this.autoDeleteForm.controls.deleteEmailsFromSpam.value,
        deleteEmailsFromTrash:
          this.autoDeleteForm.controls.deleteEmailsFromTrash.value,
        oidcSupport: this.settings.oidcSupport,
        adfsSupport: this.settings.adfsSupport,
        googleOAuthSupport: this.settings.googleOAuthSupport,
        alertSpaceUsed: this.settings.alertSpaceUsed,
        alertLevelSpaceUsed: this.settings?.alertLevelSpaceUsed,
        credemISAMSupport: this.settings.credemISAMSupport,
      };

      try {
        await this.organizationSettingsClient.createOrUpdate(updateRequest);
        if (this.settings?.credemISAMSupport) {
          const updateRequestSamlClaims = {
            claimId: this.samlClaims ? this.samlClaims.claimId : null,
            claimCode: this.form.controls.samlRtaClaim.value,
            organizationId: this.organizationId,
            role: RoleType.Rta,
          };

          await this.claimClient.createOrUpdateRtaClaimByOrganizationId(
            updateRequestSamlClaims
          );
        }
        this.userWorkspaceService.setSettingsMaxSpace(
          SpaceUsedPaymentPlanDetailTypes[this.spaceUsedPaymentPlan.value].value
        );
        this.userWorkspaceService.setSettingsAlertLevel(
          this.settings?.alertLevelSpaceUsed ?? 0
        );
        this.drawerService.closeDrawer();
      } catch (e) {
        this.failedUpdate = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName]?.hasError(errorName);
  };

  public get authTypeIsSelected(): boolean {
    return (
      this.settings.oidcSupport ||
      this.settings.adfsSupport ||
      this.settings.googleOAuthSupport ||
      this.settings.credemISAMSupport
    );
  }

  public proccessValueSpace(): void {
    if ((this.settings?.alertLevelSpaceUsed ?? 0) > 100) {
      this.settings = {
        ...(this.settings ?? {}),
        alertLevelSpaceUsed: 100,
      };
    }
    if ((this.settings?.alertLevelSpaceUsed ?? 0) < 0) {
      this.settings = {
        ...(this.settings ?? {}),
        alertLevelSpaceUsed: 0,
      };
    }
  }

  public proccessValueDays(event: Event, field: string): void {
    let processValue = !isNaN(+(event.target as HTMLInputElement).value)
      ? Math.floor(+(event.target as HTMLInputElement).value)
      : null;
    if (processValue === 0) {
      processValue = null;
    }
    this.autoDeleteForm.controls[field].setValue(processValue);
  }
}
