import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { UserMailAccountContract } from '@app-types/api/user-mail-account';
import {
  CONNECTION_STATUS,
  CONNECTION_STATUS_INFO,
} from '@app-shared/constants/connection-status';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MailAccountFoldersComponent } from '../mail-account-folders/mail-account-folders.component';
import { EMAIL_QUERY_PARAM, WEB_CODE } from '@app-shared/constants/constants';
import { GenerateMailAccountTreeService } from '@app-services/generate-mail-account-tree.service';
import { TreeItem } from '@app-types/tree.item';
import { MailFolderContract } from '@app-types/api/mail-folder';
import { MailFolderClient } from '@app-services/api/clients/mail-folder.client';
import { decodeInfoMailAccount } from '@app-utils/search-params';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '@app-services/auth/authentication.service';
import { IntervalService } from '@app-services/interval.service';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { LoggingHandler } from '@app-services/errors/logging.service';
import { SearchInfo } from '@app-types/search';

@Component({
  selector: 'app-emails-sidenav',
  standalone: true,
  imports: [
    TranslateModule,
    MatExpansionModule,
    MatTooltipModule,
    MatIconModule,
    MailAccountFoldersComponent,
    MatMenuModule,
    RouterLink,
  ],
  templateUrl: './emails-side-nav.component.html',
  styleUrls: ['./emails-side-nav.component.scss'],
})
export class EmailsSideNavComponent implements OnInit {
  getMailAccountsError: string;
  @Output() leftMenuOpen = new EventEmitter();
  @Input() settingRoute = '';
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  public mailAccountId: number;
  public mailAccountIds: number[] = [];
  public webCode: string;
  public isTreeLoaded = false; // ??
  public hideNotification = true;
  menuTopLeftPosition = { x: '0', y: '0' };
  connectionStatusName = CONNECTION_STATUS;
  connectionStatusInfo = CONNECTION_STATUS_INFO;
  public openMailAccountId: number | null;
  tree: TreeItem<number, MailFolderContract>[] = [];
  infoOpenedMailAccount: SearchInfo;

  constructor(
    protected route: ActivatedRoute,
    private router: Router,
    private permissionService: PermissionService,
    private generateMailAccountTreeService: GenerateMailAccountTreeService,
    private mailFolderClient: MailFolderClient,
    private authenticationService: AuthenticationService,
    private intervalService: IntervalService,
    public userWorkspaceService: UserWorkspaceService,
    protected loggingHandler: LoggingHandler
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
    this.getMailAccountsError = this.userWorkspaceService.mailAccountsError;
    if (!this.userWorkspaceService.mailAccounts.length) return;

    this.setInfoOpenMAilAccount(window.location.href);

    this.mailAccountIds = this.userWorkspaceService.mailAccounts.map(
      e => e.mailAccountId
    );
    this.tree = this.generateMailAccountTreeService.buildFolderTree(
      this.userWorkspaceService.userFolders
    );
    this.isTreeLoaded = true;
    this.userWorkspaceService.getBadgesForMailAccounts(this.mailAccountIds);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setInfoOpenMAilAccount(window.location.href);
      });

    !!this.userWorkspaceService.mailAccounts.length &&
      this.intervalService.startInterval(
        'badgesRefreshInterval',
        () => {
          this.userWorkspaceService.getBadgesForMailAccounts(
            this.mailAccountIds
          );
          this.openMailAccountId &&
            this.userWorkspaceService.refreshUnread({
              mailAccountId: this.openMailAccountId,
              updateBadges: false,
            });
        },
        120000,
        this.webCode
      );
  }

  setInfoOpenMAilAccount(url: string): void {
    const newUrl = new URL(url);
    if (newUrl.pathname.includes('emails')) {
      const search = newUrl.searchParams.get(EMAIL_QUERY_PARAM) ?? '';
      this.infoOpenedMailAccount = decodeInfoMailAccount(search);
      this.hideNotification =
        this.infoOpenedMailAccount?.hideNotifications !== false;
    }
    this.openMailAccountId =
      this.infoOpenedMailAccount?.acc ??
      this.userWorkspaceService.mailAccounts?.[0]?.mailAccountId ??
      null;
  }

  getMailAccountTree(
    mailAccountId: number
  ): TreeItem<number, MailFolderContract> | undefined {
    return this.tree.find(({ key }) => key === mailAccountId);
  }

  public getBadge(mailAccount: UserMailAccountContract): number | null {
    return (
      this.userWorkspaceService.mailAccountBadges.find(
        e => e.mailAccountId === mailAccount.mailAccountId
      )?.badge || null
    );
  }

  public async opened(
    event: any,
    mailAccount: UserMailAccountContract
  ): Promise<void> {
    this.userWorkspaceService.isChangeAccount = true;
    this.openMailAccountId = mailAccount.mailAccountId;
    if (!mailAccount.firstFolder) return;
    const hideNotification =
      this.userWorkspaceService.hideNotifications === false;

    this.router.navigate([this.webCode, 'emails'], {
      queryParams: {
        [EMAIL_QUERY_PARAM]: hideNotification
          ? mailAccount.firstLinkNotification
          : mailAccount.firstLink,
      },
    });
    this.userWorkspaceService.refreshUnread({
      mailAccountId: mailAccount.mailAccountId,
    });
    this.userWorkspaceService.isChangeAccount = false;
  }

  async updateDataSideNav(): Promise<void> {
    await this.userWorkspaceService.loadFolders();
    this.tree = this.generateMailAccountTreeService.buildFolderTree(
      this.userWorkspaceService.userFolders
    );
    this.openMailAccountId &&
      (await this.userWorkspaceService.refreshUnread({
        mailAccountId: this.openMailAccountId,
      }));
  }

  onRightClick(event: MouseEvent, item: any): void {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();
    if (!this.settingRoute) return;
    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item };

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  clickSettingMailAccount(item: any): void {
    this.router.navigate(
      [
        `${this.webCode}/settings/organization/${this.userWorkspaceService.currentOrganization?.organizationId}/mail-account/`,
        `${item?.mailAccountId}`,
        'general',
      ],
      {
        onSameUrlNavigation: 'reload',
      }
    );
  }

  public getTitle(mailAccount: UserMailAccountContract): string {
    return mailAccount.mailAccountName
      ? mailAccount.mailAccountName
      : (mailAccount.mailAccountEmail ?? '');
  }

  getColorText(mailAccountId: number): string {
    const dataBadges = this.userWorkspaceService.mailAccountBadges.find(
      el => el.mailAccountId === mailAccountId
    );
    return dataBadges?.connectionStatus
      ? this.connectionStatusName[dataBadges.connectionStatus].color
      : '';
  }

  getConnectionStatusTitle(mailAccountId: number): string {
    const dataBadges = this.userWorkspaceService.mailAccountBadges.find(
      el => el.mailAccountId === mailAccountId
    );
    return dataBadges?.connectionStatus
      ? this.connectionStatusName[dataBadges.connectionStatus].title
      : '';
  }

  isConnectionStatusInfo(mailAccountId: number): boolean {
    const dataBadges = this.userWorkspaceService.mailAccountBadges.find(
      el => el.mailAccountId === mailAccountId
    );
    return !!dataBadges?.connectionStatusInfo;
  }

  getConnectionStatusInfo(mailAccountId: number): string {
    const dataBadges = this.userWorkspaceService.mailAccountBadges.find(
      el => el.mailAccountId === mailAccountId
    );
    return dataBadges?.connectionStatusInfo
      ? this.connectionStatusInfo[dataBadges.connectionStatusInfo]
      : '';
  }

  getConnectStatusInfo(mailAccountId: number): string {
    const dataBadges = this.userWorkspaceService.mailAccountBadges.find(
      el => el.mailAccountId === mailAccountId
    );
    return dataBadges?.connectionStatus
      ? this.connectionStatusName[dataBadges.connectionStatus].info
      : '';
  }
}
