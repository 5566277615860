import { Pipe, PipeTransform } from '@angular/core';
import { ErrorCode } from '@app-types/enums/error-code';

@Pipe({
  name: 'metaErrors',
  standalone: true,
})
export class MetaErrorsPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case ErrorCode.ImapWrongPassword:
        return 'errorImapWrongPassword';

      case ErrorCode.EmlForEmailNotFound:
        return 'errorSystem';

      case ErrorCode.EntityDoesNotExists:
        return 'errorSystem';

      case ErrorCode.FileHasVirus:
        return 'errorSystem';

      case ErrorCode.AttachmentFileNotExist:
        return 'errorSystem';

      case ErrorCode.FileNameIsNull:
        return 'errorSystem';

      case ErrorCode.UnknownFileType:
        return 'errorSystem';

      case ErrorCode.UnhandledError:
        return 'errorSystem';

      case ErrorCode.EmlGenerationError:
        return 'errorSystem';
    }
    return 'errorSystem';
  }
}
