import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TreeItem } from '@app-types/tree.item';
import {
  MailFolderContract,
  MailFolderContractWithFullNameForResponse,
  MailFolderUnreadCount,
} from '@app-types/api/mail-folder';
import { MailFolderClient } from '@app-services/api/clients/mail-folder.client';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import {
  MAT_MENU_DEFAULT_OPTIONS,
  MatMenuModule,
  MatMenuTrigger,
} from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { SearchContext } from '@app-types/search-context';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '../common/loader/loader.component';
import { MatIconModule } from '@angular/material/icon';
import { EMAIL_QUERY_PARAM, WEB_CODE } from '@app-shared/constants/constants';
import { DrawerComponent } from '../common/drawer/drawer.component';
import { DrawerService } from '@app-services/drawer.service';
import { MailFolderCreateDialogComponent } from './mail-folder-create-dialog/mail-folder-create-dialog.component';
import { delay } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { SearchInfo } from '@app-types/search';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-mail-account-folders',
  standalone: true,
  imports: [
    MatTreeModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    LoaderComponent,
    DrawerComponent,
    MailFolderCreateDialogComponent,
  ],
  templateUrl: './mail-account-folders.component.html',
  styleUrls: ['./mail-account-folders.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'panelClassMenu' },
    },
  ],
})
export class MailAccountFoldersComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() mailAccountId: number;
  @Input() isOpen: boolean;
  @Input() tree: TreeItem<number, MailFolderContract> | undefined;
  @Input() folderCounts: MailFolderUnreadCount[] = [];
  @Input() infoOpenedMailAccount: SearchInfo;
  @Output() getBadgesForMailAccounts = new EventEmitter();
  @Output() updateDataSideNav = new EventEmitter();
  @Output() leftMenuOpen = new EventEmitter();

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('createFolderTemplate') createFolderTemplate!: TemplateRef<any>;
  @ViewChild('renameFolderTemplate') renameFolderTemplate!: TemplateRef<any>;

  public searchContext: SearchContext;
  public loadError = false;
  public webCode: string;
  public dataSource = new MatTableDataSource<
    TreeItem<number, MailFolderContract>
  >();

  public apiError: string;
  treeControl = new NestedTreeControl<TreeItem<number, MailFolderContract>>(
    node => node.children
  );
  public selectedItem: TreeItem<number, MailFolderContract>;
  public selectedFolderId: number;
  public mailFolderTypes = MailFolderType;

  menuTopLeftPosition = { x: '0', y: '0' };
  public visibleFullScreen = false;

  public parentMailFolderIdForCreation: number | null;
  public editingFolder: MailFolderContractWithFullNameForResponse | null;
  public currentMailAccountId: number;
  public parentMailFolderTypeForCreation: number | null;
  public openedFoldersIds: number[] = [];

  constructor(
    public matchError: MatchError,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private mailFolderClient: MailFolderClient,
    public dialog: MatDialog,
    private drawerService: DrawerService,
    private observer: BreakpointObserver,
    private userWorkspaceService: UserWorkspaceService
  ) {}

  async ngOnInit(): Promise<any> {
    this.webCode =
      this.activateRoute.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
    this.tree?.children && (this.dataSource.data = this.tree.children);

    this.isOpen &&
      (await this.userWorkspaceService.refreshUnread({
        mailAccountId: this.mailAccountId,
        updateBadges: false,
      }));

    if (this.infoOpenedMailAccount?.folder && this.tree?.children) {
      const openFolders = this.openFolder(
        this.tree.children,
        this.infoOpenedMailAccount.folder
      );
      this.openedFoldersIds.push(...openFolders);
    }
  }

  openFolder(
    folders: TreeItem<number, MailFolderContract>[],
    openFolder: number
  ): number[] {
    let result: number[] = [];

    folders.forEach(({ children, key, parentKey }) => {
      key === openFolder && parentKey && result.push(parentKey);

      if (children && children.length) {
        const childResult = this.openFolder(children, openFolder);

        if (childResult.length) {
          result = [...result, ...childResult];
          parentKey && result.push(parentKey);
        }
      }
    });

    return result;
  }

  public isOpenedFolder(folderId: number): boolean {
    return !this.openedFoldersIds.includes(folderId);
  }

  expandCollapseTree(item: { data: { mailFolderId: number } }): void {
    const isOpened = this.openedFoldersIds.includes(item.data.mailFolderId);
    if (!isOpened) {
      this.openedFoldersIds.push(item.data.mailFolderId);
    } else {
      this.openedFoldersIds = this.openedFoldersIds.filter(
        e => e !== item.data?.mailFolderId
      );
    }
  }

  hasChild = (_: number, node: TreeItem<number, MailFolderContract>) =>
    !!node.children && node.children.length > 0;

  async selectFolder(
    folderItem: TreeItem<number, MailFolderContract>
  ): Promise<void> {
    this.selectedItem = folderItem;
    folderItem.data && (this.selectedFolderId = folderItem.data.mailFolderId);
    if (!folderItem.data?.folderType) return;
    const hideNotification =
      this.userWorkspaceService.hideNotifications === false;

    this.router.navigate([this.webCode, 'emails'], {
      onSameUrlNavigation: 'reload',
      queryParams: {
        [EMAIL_QUERY_PARAM]: hideNotification
          ? folderItem.data.firstLinkNotification
          : folderItem.data.firstLink,
      },
    });
    this.userWorkspaceService.refreshUnreadForFolder(
      folderItem.data?.mailFolderId ?? 0,
      this.mailAccountId,
      folderItem.data?.folderType === MailFolderType.Inbox
    );
    !this.visibleFullScreen && this.leftMenuOpen.emit();
  }

  public getCountForFolder(folderId: number): number | null {
    return (
      this.folderCounts.find(e => e.mailFolderId === folderId)?.count || null
    );
  }

  onRightClick(event: MouseEvent, item: MailFolderContract): void {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item };
    // we open the menu
    if (
      item.folderType !== MailFolderType.Inbox &&
      item.folderType !== MailFolderType.Archive &&
      item.folderType !== MailFolderType.Sent
    ) {
      return;
    } else {
      this.matMenuTrigger.openMenu();
    }
  }

  public createFolder(item: MailFolderContract): void {
    this.parentMailFolderTypeForCreation = item.folderType;
    this.parentMailFolderIdForCreation = item.mailFolderId;
    this.drawerService.openDrawer(this.createFolderTemplate);
  }

  public async onSubmitCreation(): Promise<void> {
    this.updateDataSideNav.emit();
    this.onCloseCreateModalForm();
  }

  public onCloseCreateModalForm(): void {
    this.parentMailFolderIdForCreation = null;
    this.parentMailFolderTypeForCreation = null;
  }

  public renameFolder(folder: MailFolderContractWithFullNameForResponse): void {
    this.parentMailFolderTypeForCreation = folder.folderType;
    this.editingFolder = folder;
    this.drawerService.openDrawer(this.renameFolderTemplate);
  }

  public async onSubmitRename(): Promise<void> {
    this.updateDataSideNav.emit();
    this.onCloseRenameModalForm();
  }

  public onCloseRenameModalForm(): void {
    this.editingFolder = null;
    this.parentMailFolderTypeForCreation = null;
  }

  public async onDeleteBtnClicked(mailFolderId: number): Promise<void> {
    await this.mailFolderClient.delete({ id: mailFolderId });
  }

  public deleteFolder(folder: MailFolderContractWithFullNameForResponse): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'confirmFolderDeletion',
        subTitles: [
          {
            title: 'folderDeletion',
            subTitle: folder.fullName,
          },
        ],
        onDelete: async () =>
          await this.onDeleteBtnClicked(folder.mailFolderId),
      },
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isDeleted) {
        this.updateDataSideNav.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.tree) {
      this.dataSource.data = changes?.tree.currentValue?.children ?? [];
    }
  }

  ngAfterViewInit(): void {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe(res => {
        this.visibleFullScreen = !res.matches;
      });
  }
}
