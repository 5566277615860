import { Injectable } from '@angular/core';
import { TreeItem } from '../types/tree.item';
import {
  MailFolderContract,
  UserFolderContract,
} from '../types/api/mail-folder';
import { MailFolderType } from '../types/enums/mail-folder.type';
import { encodeInfoMailAccount } from '@app-utils/search-params';

@Injectable({
  providedIn: 'root',
})
export class GenerateMailAccountTreeService {
  constructor() {}

  getLinks(
    folder: number,
    folderType: MailFolderType,
    mailAccountId: number
  ): { firstLink: string; firstLinkNotification: string } {
    const firstLink = encodeInfoMailAccount(mailAccountId, folder, folderType);

    const firstLinkNotification = encodeInfoMailAccount(
      mailAccountId,
      folder,
      folderType,
      {
        hideNotifications: false,
      }
    );
    return { firstLink, firstLinkNotification };
  }

  buildFolderTree(
    folders: UserFolderContract[]
  ): TreeItem<number, MailFolderContract>[] {
    const tree: TreeItem<number, MailFolderContract>[] = [];
    // root mail account nodes
    for (const f of folders) {
      if (!tree.find(x => x.key === f.mailAccountId)) {
        const { firstLink, firstLinkNotification } = this.getLinks(
          f.mailFolderId,
          f.folderType,
          f.mailAccountId
        );
        const newTree = {
          key: f.mailAccountId,
          data: {
            mailFolderId: f.mailFolderId,
            mailAccountId: f.mailAccountId,
            name: f.mailFolderName,
            folderType: f.folderType,
            parentId: f.parentFolderId,
            creationType: f.creationType,
            firstLink,
            firstLinkNotification,
          },
          children: [],
          parentKey: f?.parentFolderId || undefined,
        };
        tree.push(newTree);
      }
    }

    // mail folder nodes
    for (const mailAccountNode of tree) {
      const mailAccountId = mailAccountNode.key;
      const mailFolders = folders.filter(
        f => f.mailAccountId === mailAccountId
      );
      const rootFolders = mailFolders.filter(f => !f.parentFolderId);
      for (const rootFolder of rootFolders) {
        const rootFolderNode = this.buildFolderNode(rootFolder, mailFolders);
        mailAccountNode.children &&
          mailAccountNode.children.push(rootFolderNode);
      }
    }
    const folderTypeOrder = [
      MailFolderType.Inbox,
      MailFolderType.Sent,
      MailFolderType.Archive,
      MailFolderType.Outbox,
      MailFolderType.Validation,
      MailFolderType.Draft,
      MailFolderType.Trash,
      MailFolderType.Spam,
    ];
    tree.forEach(e => {
      !!e.children &&
        e.children.sort((a, b) => {
          const aFolderType = a?.data?.folderType;
          const bFolderType = b?.data?.folderType;

          if (aFolderType === undefined || bFolderType === undefined) {
            return aFolderType === undefined ? 1 : -1;
          }

          return (
            folderTypeOrder.indexOf(aFolderType) -
            folderTypeOrder.indexOf(bFolderType)
          );
        });
    });

    return tree;
  }

  buildFolderNode(
    folder: UserFolderContract,
    folders: UserFolderContract[]
  ): TreeItem<number, MailFolderContract> {
    const node = new TreeItem<number, MailFolderContract>();
    node.key = folder.mailFolderId;
    const { firstLink, firstLinkNotification } = this.getLinks(
      folder.mailFolderId,
      folder.folderType,
      folder.mailAccountId
    );
    node.data = {
      creationType: folder.creationType,
      mailFolderId: folder.mailFolderId,
      mailAccountId: folder.mailAccountId,
      name: folder.mailFolderName,
      folderType: folder.folderType,
      parentId: folder.parentFolderId,
      canReassign: true,
      canValidate: true,
      firstLink,
      firstLinkNotification,
    };
    node.children = [];
    folder.parentFolderId && (node.parentKey = folder.parentFolderId);

    const children = folders.filter(
      f => f.parentFolderId === folder.mailFolderId
    );

    for (const childFolder of children) {
      node.children.push(this.buildFolderNode(childFolder, folders));
    }

    return node;
  }
}
