import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ImportEmlClient } from '@app-services/api/clients/import-eml.client';
import { FileInfo } from '@app-types/api/importEml';
import { FormatStateImportEmlPipe } from '@app-pipes/format-state-import-eml.pipe';
import { FormatMessageImportEmlPipe } from '@app-pipes/format-message-import-eml.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoaderComponent } from '../common/loader/loader.component';
import { DatePipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { SearchBarComponent } from '../common/search-bar/search-bar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NoDataComponent } from '../common/no-data/no-data.component';
import { ImportEmlState } from '@app-types/enums/ImportEml';
import { ErrorsPipe } from '@app-pipes/error-code.pipe';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MailFolderContract } from '@app-types/api/mail-folder';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { MailFolderService } from '@app-services/mail-folder/mail.folder.service';
import { MatchError } from '@app-services/errors/error-matcher';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';

@Component({
  selector: 'app-import-eml',
  templateUrl: './import-eml.component.html',
  styleUrls: ['./import-eml.component.scss'],
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatFormFieldModule,
    MatTableModule,
    LoaderComponent,
    FormatStateImportEmlPipe,
    DatePipe,
    MatMenuModule,
    NgxFileDropModule,
    SearchBarComponent,
    MatTooltipModule,
    FormatMessageImportEmlPipe,
    NoDataComponent,
    ErrorsPipe,
    MatSelectModule,
    SkeletonFormDrawerComponent,
    SomethingWentWrongComponent,
  ],
})
export class ImportEmlComponent implements OnInit, OnDestroy {
  @Input() public mailAccountId: number;
  @Input() public mailFolderId: number;
  @Output() loading = new EventEmitter<boolean>();
  @Output() closeDrawer = new EventEmitter();

  selectedMailFolderId: number;
  mailFolders: MailFolderContract[];
  foldersError = false;
  isLoadingFolders = false;
  isUploaded = false;
  maxEml = 100;
  currentPage = 1;
  progressValue = 0;
  progressValueCount = 0;
  textError = '';
  filesError = '';
  dataSource: MatTableDataSource<FileInfo> = new MatTableDataSource<FileInfo>(
    []
  );
  originalDataSource: FileInfo[] = [];
  filesQue: File[] = [];
  uploadedFiles: FileInfo[] = [];
  isLoading = false;
  importEmlState = ImportEmlState;
  accept = [
    'application/zip',
    'application/vnd.rar',
    'application/x-rar-compressed',
    'application/x-zip-compressed',
    `.zip`,
    'message/rfc822',
    '.eml',
  ];

  searchValue = '';
  public filter: ImportEmlState | null = null;
  menuState: { id: number; title: string }[] = [];

  formatStateImportEmlPipe = new FormatStateImportEmlPipe();
  formatMessageImportEmlPipe = new FormatMessageImportEmlPipe();

  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = [
    'folder',
    'parentFileName',
    'fileName',
    'state',
    'stateDateTime',
    'message',
  ];

  constructor(
    private importEmlClient: ImportEmlClient,
    private translate: TranslateService,
    private mailFolderService: MailFolderService,
    public matchError: MatchError
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectedMailFolderId = this.mailFolderId;
    await this.getFolders();
    this.menuState = Object.keys(ImportEmlState)
      .filter(k => !(parseInt(k, 10) >= 0))
      .map(key => ({
        id: ImportEmlState[key as keyof typeof ImportEmlState],
        title: this.formatStateImportEmlPipe.transform(
          ImportEmlState[key as keyof typeof ImportEmlState]
        ),
      }));
  }

  async getFolders(): Promise<void> {
    this.foldersError && (this.foldersError = false);
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoadingFolders = true;
    }, 50);

    try {
      const response = await this.mailFolderService.getFoldersForAccountFlat(
        this.mailAccountId
      );
      this.mailFolders = response.filter(
        el =>
          el.folderType === MailFolderType.Inbox ||
          el.folderType === MailFolderType.Sent
      );
    } catch (error) {
      this.foldersError = true;
      this.matchError.logError(error);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoadingFolders = false;
    }
  }

  getFolderName(folderId?: number): string {
    const infoFolder = this.mailFolders.find(
      el => el.mailFolderId === folderId
    );
    return infoFolder
      ? infoFolder.creationType === 1
        ? 'folderType' + infoFolder.folderType
        : infoFolder.name
      : '';
  }

  onFolderChange($event: MatSelectChange): void {
    this.selectedMailFolderId = $event.value;
  }

  checkFormat(file: File): boolean {
    return (
      this.accept.includes(file.type) ||
      file.name.endsWith('.eml') ||
      file.name.endsWith('.zip')
    );
  }

  onSearchValueChanged($event: string): void {
    this.searchValue = $event;
  }

  loadData(): void {
    if (!this.searchValue && !this.filter) {
      this.dataSource.data = this.originalDataSource;
    } else {
      this.dataSource.data = this.originalDataSource.filter(
        el =>
          (this.searchValue
            ? Object.entries(el).some(([key, value]) => {
                if (
                  this.displayedColumns.includes(key) ||
                  key === 'mailFolderId'
                ) {
                  if (key === 'message') {
                    let newValue = '';
                    this.translate
                      .get(
                        this.formatMessageImportEmlPipe.transform(+value) || ' '
                      )
                      .subscribe((res: string) => {
                        newValue = res;
                      });
                    return newValue
                      .toLowerCase()
                      .includes(this.searchValue.trim().toLowerCase());
                  }
                  if (key === 'state') {
                    let newValueState = '';
                    this.translate
                      .get(
                        this.formatStateImportEmlPipe.transform(+value) || ' '
                      )
                      .subscribe((res: string) => {
                        newValueState = res;
                      });
                    return newValueState
                      .toLowerCase()
                      .includes(this.searchValue.trim().toLowerCase());
                  }
                  if (key === 'mailFolderId') {
                    let name = this.getFolderName(+value);
                    this.translate
                      .get(name || ' ')
                      .subscribe((res: string) => (name = res));
                    return name
                      .toLowerCase()
                      .includes(this.searchValue.trim().toLowerCase());
                  }
                  return String(value)
                    .toLowerCase()
                    .includes(this.searchValue.trim().toLowerCase());
                }
                return false;
              })
            : true) && (this.filter ? el.state === this.filter : true)
      );
    }
  }

  async uploadFile(file: File): Promise<void> {
    try {
      const request = {
        mailAccountId: this.mailAccountId,
        mailFolderId: this.selectedMailFolderId,
        file,
      };
      const response = await this.importEmlClient.uploadEML(request);
      response.data.forEach(el => {
        this.uploadedFiles.push(el);
      });
    } catch (e: any) {
      console.log(e);
      this.selectedMailFolderId &&
        this.mailAccountId &&
        this.uploadedFiles.push({
          importEmlId: 0,
          organizationId: 0,
          mailAccountId: this.mailAccountId,
          mailFolderId: this.selectedMailFolderId,
          fileName: file.name,
          parentFileName: '',
          userId: 0,
          state: ImportEmlState.UploadError,
          stateDateTime: new Date().toISOString(),
          message: e.Code,
        });
    } finally {
      this.progressValue += this.progressValueCount;
    }
  }

  async uploadFiles(
    files: File[] | FileInfo[],
    isImport: boolean
  ): Promise<void> {
    for (let i = 0; i < files.length; i++) {
      if (isImport) {
        await this.importFile([(files[i] as FileInfo).importEmlId]);
      } else {
        await this.uploadFile(files[i] as File);
      }
    }
  }

  async loadingDataChunks(
    files: File[] | FileInfo[],
    isImport = false
  ): Promise<void> {
    const chunksOfFiles = [];
    for (let i = 0; i < files.length; i += 10) {
      chunksOfFiles.push(files.slice(i, i + 10));
    }

    for (let i = 0; i < chunksOfFiles.length; i++) {
      await this.uploadFiles(chunksOfFiles[i], isImport);
    }
  }

  changeData(arr: FileInfo[], importEmlId: number, res: any): FileInfo[] {
    return arr.map(el => {
      if (el.importEmlId === importEmlId) {
        return {
          ...el,
          ...res,
        };
      } else {
        return el;
      }
    });
  }

  async importFile(importEmlId: number[]): Promise<void> {
    const request = { importEmlIds: importEmlId };
    try {
      const response = await this.importEmlClient.importEML(request);
      this.originalDataSource = this.changeData(
        this.originalDataSource,
        importEmlId[0],
        response?.data?.[0] ?? {}
      );
      this.dataSource.data = this.changeData(
        this.dataSource.data,
        importEmlId[0],
        response?.data?.[0] ?? {}
      );
    } catch (e: any) {
      const error = {
        state: ImportEmlState.ImportError,
        stateDateTime: new Date().toISOString(),
        message: e.Code,
      };
      this.originalDataSource = this.changeData(
        this.originalDataSource,
        importEmlId[0],
        error
      );
      this.dataSource.data = this.changeData(
        this.dataSource.data,
        importEmlId[0],
        error
      );
    }
  }

  async onDrop(event: NgxFileDropEntry[]): Promise<void> {
    this.textError && (this.textError = '');
    this.filesError && (this.filesError = '');
    if (this.maxEml < event.length) {
      this.textError = 'errorImportEmlMaxCount';
      return;
    }
    const filesQue: File[] = [];
    this.isLoading = true;
    this.loading.emit(true);
    this.progressValue = 1;

    const filePromises = event.map(fileInfo => {
      return new Promise<void>((resolve, reject) => {
        const fileEntry = fileInfo.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          if (this.checkFormat(file)) {
            filesQue.push(file);
          } else {
            this.filesError = `${this.filesError} ${file.name}`;
          }
          resolve();
        }, reject);
      });
    });

    await Promise.all(filePromises);
    this.filesQue = filesQue;
    this.progressValueCount = Math.round(100 / filesQue.length);
    await this.loadingDataChunks(this.filesQue);
    this.progressValue = 100;
    this.isLoading = false;
    this.dataSource = new MatTableDataSource([
      ...this.uploadedFiles,
      ...this.originalDataSource,
    ]);
    this.originalDataSource = this.dataSource.data;
    this.searchValue = '';
    this.filter = null;
    this.dataSource.sort = this.sort;
    this.filesQue = [];
    this.progressValue = 0;
    this.progressValueCount = 0;
    const filesForImport = [
      ...this.uploadedFiles.filter(el => !!el.importEmlId),
    ];
    this.uploadedFiles = [];
    await this.loadingDataChunks(filesForImport, true);
    this.loading.emit(false);
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${amountPages}`;
  }

  setFilterChange(name: string, val: ImportEmlState, remove?: boolean): void {
    this.filter = val;
    if (remove) {
      this.filter = null;
    }
    this.loadData();
  }

  reload(): void {
    this.searchValue = '';
    this.filter = null;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.closeDrawer.emit();
  }
}
