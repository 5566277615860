import { InfoMeta } from '@app-types/meta';
import { StatusMeta } from '@app-types/enums/status-meta';
import { ObjectTypeString } from '@app-types/common';

export const INFO_META: InfoMeta = {
  [StatusMeta.Success]: {
    title: 'messageSent',
    status: 'ok',
    color: 'accent',
    icon: 'check_circle',
  },
  [StatusMeta.Pending]: {
    title: 'messagePending',
    status: 'pending',
    color: 'primary',
    icon: 'donut_large',
  },
  [StatusMeta.Failure]: {
    title: 'messageNotSent',
    status: 'error',
    color: 'warn',
    icon: 'error',
  },
  [StatusMeta.SpecialError]: {
    title: 'messageNotSent',
    status: 'error',
    color: 'warn',
    icon: 'error',
  },
  [StatusMeta.PartlySuccess]: {
    title: 'messagePartiallySent',
    status: 'ok',
    color: 'warn',
    icon: 'warn',
  },
};

export const INFO_META_APP_ERROR = {
  [StatusMeta.Success]: {
    title: 'messageSentAppError',
  },
  [StatusMeta.Pending]: {
    title: 'messagePendingAppError',
  },
  [StatusMeta.Failure]: {
    title: 'messageNotSent',
  },
  [StatusMeta.SpecialError]: {
    title: 'messageNotSent',
  },
  [StatusMeta.PartlySuccess]: {
    title: 'messagePartiallySentAppError',
  },
};

export const META_ERRORS: ObjectTypeString = {
  'Error: authentication failed': 'errorConnectionLostAuthServer',
  'Temporary authentication failure': 'errorConnectionLostAuthServer',
  'invalid user ID or password': 'errorConnectionLostAuthServer',
  'Unhandled error while check attachments for virus':
    'errorAntivirusVerification',
  'Send Email Error': 'sendEmailError',
  'Please make sure that antivirus and firewall software are disabled or configured correctly':
    'errorConnection',
  'Sender address rejected: not owned by user': 'errorSenderAddressRejected',
  'Gateway error': 'errorConnection',
  'Bad recipient address syntax': 'errorInvalidRecipient',
  'maximum message size exceeded': 'errorMaximumMessageSize',
  'Chunk exceeds message size limit': 'errorMaximumMessageSize',
  'Temporary local problem - please try later': 'errorTemporary',
};
