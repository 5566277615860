import { Component, Input, OnInit } from '@angular/core';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { MatDialog } from '@angular/material/dialog';
import { EmailClient } from '@app-services/api/clients/email-client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconModule } from '@angular/material/icon';
import { StatusMeta } from '@app-types/enums/status-meta';
import { TitleCasePipe } from '@angular/common';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';
import {
  GeneralError,
  Meta,
  MetaDataSource,
  MetaResponse,
} from '@app-types/meta';
import { INFO_META, INFO_META_APP_ERROR } from '@app-shared/constants/meta';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MetaService } from '@app-services/meta-service';
import { MatExpansionModule } from '@angular/material/expansion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ErrorCode } from '@app-types/enums/error-code';
import { MetaErrorsPipe } from '@app-pipes/meta-errors.pipe';
import { SnackbarService } from '@app-services/snackbar.service';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { EmailGetByIdResponse } from '@app-types/api/email';

@Component({
  selector: 'app-email-meta',
  templateUrl: './email-meta.component.html',
  styleUrls: ['./email-meta.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    NgxSkeletonLoaderModule,
    SkeletonFormDrawerComponent,
    MatTableModule,
    MatIconModule,
    TitleCasePipe,
    MatExpansionModule,
    ClipboardModule,
    MetaErrorsPipe,
  ],
})
export class EmailMetaComponent implements OnInit {
  @Input() emailId: number;
  @Input() title: string;
  public isLoading = false;
  public cannotLoadMeta = false;

  statusMetaEnum = StatusMeta;
  info = INFO_META;
  infoAppError = INFO_META_APP_ERROR;

  public meta: Meta | null;
  public metaString: string;

  isMetaString = false;
  originalResult = '';

  appError: ErrorCode | null = null;
  smtpMessage = '';

  status: StatusMeta;
  sendingMessage = '';

  globalErrors: GeneralError[];
  provider = '';

  dataSource: MatTableDataSource<MetaDataSource> =
    new MatTableDataSource<MetaDataSource>([]);

  public displayedColumns: string[] = ['email', 'status', 'message'];

  constructor(
    public matchError: MatchError,
    public dialog: MatDialog,
    private emailClient: EmailClient,
    private metaService: MetaService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getEmailDetail();
  }

  getStatus(): StatusMeta {
    if (this.meta?.Status || this.meta?.Status === StatusMeta.Success) {
      return !this.meta.GeneralErrors.length &&
        this.meta.Status === StatusMeta.Failure
        ? !!this.meta.RejectedRecipients.length &&
          !!this.meta.ApprovedRecipients.length
          ? StatusMeta.PartlySuccess
          : this.meta.Status
        : this.meta.Status;
    } else {
      return StatusMeta.SpecialError;
    }
  }

  getGeneralsError(): GeneralError[] {
    return this.meta?.GeneralErrors?.length
      ? this.meta.GeneralErrors.map(el => ({
          ...el,
          Message: this.metaService.getText(el.Message, this.originalResult),
        }))
      : [];
  }

  getDataTable(): MetaDataSource[] {
    return [
      ...(this.meta?.RejectedRecipients ?? []),
      ...(this.meta?.ApprovedRecipients ?? []),
    ].map(el => ({
      email: el,
      status:
        this.status === StatusMeta.PartlySuccess
          ? this.meta?.RejectedRecipientsErrors?.[el]
            ? StatusMeta.Failure
            : this.status
          : this.status,
      message: this.meta?.RejectedRecipientsErrors?.[el]
        ? this.meta?.RejectedRecipientsErrors[el].Message
          ? this.metaService.getText(
              this.meta.RejectedRecipientsErrors[el].Message,
              this.originalResult
            )
          : ''
        : '',
    }));
  }

  async getEmailDetail(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadMeta = false;
    const request = new BaseGetByIdRequest(this.emailId);
    try {
      const result = await this.emailClient.getById(request);
      !result.email.meta && (result.email.meta = '');
      this.isMetaString = typeof result.email.meta === 'string';

      const data: string | MetaResponse = result.email.meta;

      if (!this.isMetaString) {
        this.originalResult = (data as MetaResponse)?.providerStatus
          ? JSON.stringify((data as MetaResponse).providerStatus, null, 2)
          : '';

        (data as MetaResponse).appError &&
          (this.appError = (data as MetaResponse).appError);

        this.smtpMessage = (data as MetaResponse)?.smtpMessage
          ? this.metaService.getText(
              (data as MetaResponse).smtpMessage ?? '',
              this.originalResult
            )
          : '';

        !!(data as MetaResponse)?.providerStatus &&
          (this.meta = (data as MetaResponse).providerStatus);

        this.status = this.getStatus();

        this.sendingMessage = this.appError
          ? (this.infoAppError[this.status]?.title ??
            this.infoAppError[this.statusMetaEnum.Failure].title)
          : (this.info[this.status]?.title ??
            this.info[this.statusMetaEnum.Failure].title);

        this.globalErrors = this.getGeneralsError();

        this.provider = (data as MetaResponse).provider;

        this.dataSource.data = this.getDataTable();
      } else {
        this.metaString = data as string;
      }
    } catch (e) {
      this.cannotLoadMeta = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  getEmailStatus(email: MetaDataSource): string {
    return this.info[email.status].status;
  }

  copy(): void {
    const config: MatSnackBarConfig = {
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      duration: 1000,
    };
    this.snackbarService.openSnackBar('copiedStatus', '', config);
  }
}
