<div class="drawer-content-screen meta">
  @if(isLoading) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(cannotLoadMeta) {
    <div class="w-full h-full">
      <app-something-went-wrong
        [showPicture]="false"
        description="Cannot load notes"
        (retryEmitter)="getEmailDetail()"
      ></app-something-went-wrong>
    </div>
  }

  @if(!isLoading && !cannotLoadMeta) {
    @if(title) {
      <div class="drawer-content-screen__title">{{ title | translate }}</div>
    }
    @if(!isMetaString) {
      <div class="drawer-content-screen__body flex flex-col gap-6">
        <div class="flex flex-col gap-1 px-[5px]">
          <div class="font-bold">
            {{ sendingMessage | translate }}
          </div>
          @if(appError) {
            <div class="text-red-600 first-letter:uppercase">{{ appError | metaErrors | translate }}</div>
          }
          @if(smtpMessage) {
            <div class="text-red-600 first-letter:uppercase">{{ smtpMessage | translate }}</div>
          }
          @if(!!globalErrors?.length) {
            <div class="flex-col-container-1 mb-4 items-start">
              @for(item of globalErrors; track item) {
                <div class="text-red-600 first-letter:uppercase">{{ item.Message | translate }}</div>
              }
            </div>
          }
        </div>
        @if(!!dataSource.data.length) {
          <div class="flex flex-col gap-2">
            <div class="font-medium text-[15px] px-[5px]">
              @if(provider) {
                <span class="first-letter:uppercase break-all">
                  {{ provider }}
                </span>
              }
              {{ 'providerStatus' | translate }}
            </div>
            <table mat-table class="w-full" [dataSource]="dataSource">
              <ng-container matColumnDef="email">
                <th class="w-[40%]" mat-header-cell *matHeaderCellDef>{{ "emailTo" | translate }}</th>
                <td class="w-[40%]" mat-cell *matCellDef="let email">
                  <span class="break-all">{{ email.email }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th
                  class="w-[20%]"
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                >
                {{ "status" | translate }}
                </th>
                <td class="w-[20%]" mat-cell *matCellDef="let email">
                <div class="flex gap-1 items-center">
                  <div>{{ getEmailStatus(email) | translate }}</div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="message">
                <th
                  class="w-[40%]"
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                >
                {{ "message" | translate }}
                </th>
                <td class="w-[40%]" mat-cell *matCellDef="let email">
                <div class="first-letter:uppercase break-all">{{ email.message | translate }}</div>
                </td>
              </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row;
                  columns: displayedColumns"
                  [class.row-error]="row.status === statusMetaEnum.Failure"
                ></tr>
            </table>
          </div>
        }
        @if(!!originalResult) {
          <div class="w-full flex flex-col gap-4">
            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="font-medium">{{ "showOriginalJson" | translate }}</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="relative overflow-x-auto">
                  <pre class="bg-sidenav rounded p-4"><code class="break-all">{{ originalResult }}</code></pre>
                  <mat-icon
                    (click)="copy()"
                    class="absolute right-2 top-2 text-[16px] text-center cursor-pointer"
                    [cdkCopyToClipboard]="originalResult"
                  >content_copy</mat-icon>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        }
      </div>
    } @else {
      <div class="flex-none drawer-content-screen__body" style="overflow-wrap: anywhere">
        {{ metaString }}
      </div>
    }
  }
</div>
